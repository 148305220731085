import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Modal,Dropdown} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import PagetTitle from "../components/PageTitle";
import baseURL from '../Config';
import AthenaSaaSPrivacyPolicy from '../components/AthenaSaaSPrivacyPolicy';
import AthenaSaaSTermsOfUse from '../components/AthenaSaaSTermsOfUse';

function EnterpriseLogin() {
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);
    const navigate = useNavigate();

    const [emailid, setEmailID] = useState("");
    const [message,setMessage] = useState("");
    const [serverinfo,setServerInfo] = useState();

    const handleLoginUser = async (e) => {
      e.preventDefault();
        try {
          let res = await fetch(baseURL+"get_user_tenancy", {
            method: "POST",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
            },
            body:JSON.stringify({emailid: emailid})
          })
          .then((response) => {
            console.log('response', response);
            setMessage("");
            return response.text();
           })
          .then(data => {  
            console.log('data', data);
            if(data.indexOf("tenancy_url") > -1){
                data = JSON.parse(data).items;
                console.log(data);
                if(data.length > 1){
                    setServerInfo(data);  
                  }else{
                    let url = data[0].tenancy_url
                    window.location.replace(url);
                  }
             }
             else{
                setServerInfo("");  
                setMessage("No tenancies found for the given email id");
            }
          });
        }catch (err) {
          console.log("error"+err);
        }
    }

    const [showTermsOfUse, setShowTermsOfUse] = useState(false);
    const handleCloseTermsOfUse = () => setShowTermsOfUse(false);
    const handleShowTermsOfUse = () => setShowTermsOfUse(true);

    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const handleClosePrivacyPolicy = () => setShowPrivacyPolicy(false);
    const handleShowPrivacyPolicy = () => setShowPrivacyPolicy(true);

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if(searchParams.get("enterprise_login_privacy_policy") === 'true') {
            setShowPrivacyPolicy(true);
        } else {
            setShowPrivacyPolicy(false);
        }

        if(searchParams.get("enterprise_login_terms_of_use") === 'true') {
            setShowTermsOfUse(true);
        } else {
            setShowTermsOfUse(false);
        }
    },[]);

    return (
        <>
            <div className="login">
                <Container fluid>
                    <Row>
                        <Col className="login-image"><img src={require('./../assets/images/login.jpg')} alt="Login" className="img-responsive" /></Col>
                        <Col>
                            <div className="form-wrapper">
                                <div className="logo">
                                    <Link to="/" ><img src={require('./../assets/images/logo.png')} alt="Athena" width="80" height="80" /> ATHENA</Link>
                                </div>
                                <h1>Analytics-as-a-Service Platform</h1>
                                <form onSubmit={handleLoginUser}>
                                    <div><input type="email" required placeholder="Email ID" value={emailid} onChange={(e) => setEmailID(e.target.value)}/></div>
                                    {
                                    /*<div><input type="password" placeholder="Password" /></div>
                                    <div className="forgot-pwd">Forgot Password?</div>*/
                                    }
                                       {serverinfo ? (
                                        <Dropdown className='pt-3'>
                                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">Select Tenancy</Dropdown.Toggle>
                                            <Dropdown.Menu variant="dark">
                                                {serverinfo.map((item,index) => (
                                                    <Dropdown.Item href={item.tenancy_url} key={index}>{item.tenancy_name}</Dropdown.Item>
                                                ))}
                                                {/* <Dropdown.Item href="http://localhost:3000/ci" key="1">SaaS-Demo</Dropdown.Item>
                                                <Dropdown.Item href="http://localhost:3000/ra" key="2">RisingAheadConsoleApp</Dropdown.Item> */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                     <></>
                                    )}
                                   <div className="text-danger text-center pt-3" role="alert"><small>{message}</small></div>
                                    <div className="buttons">
                                        <button type="reset" className="btn-transparent" onClick={() => navigate(-1)}>Cancel</button>
                                        {/* <button type="submit" className="btn-yellow" onClick={handleShowError}>Sign in</button> */}
                                        <button type="submit" className=""></button>
                                    </div>
                                    <ul className="links mt-5">
                                        <li><Link to="#" onClick={handleShowTermsOfUse}>Terms of Use</Link></li>
                                        <li><Link to="#" onClick={handleShowPrivacyPolicy}>Privacy Policy</Link></li>
                                    </ul>
                                    <Modal centered show={showError} onHide={handleCloseError} backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>Invalid Username / Password</Modal.Body>
                                        <Modal.Footer>
                                            <button onClick={handleCloseError}>Close</button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal centered show={showTermsOfUse} onHide={handleCloseTermsOfUse} backdrop="static" keyboard={false} className="terms-policies">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Terms and Conditions</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body><AthenaSaaSTermsOfUse /></Modal.Body>
                                        <Modal.Footer>
                                            <button onClick={handleCloseTermsOfUse}>Back</button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal centered show={showPrivacyPolicy} onHide={handleClosePrivacyPolicy} backdrop="static" keyboard={false} className="terms-policies">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Privacy Policy</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body><AthenaSaaSPrivacyPolicy /></Modal.Body>
                                        <Modal.Footer>
                                            <button onClick={handleClosePrivacyPolicy}>Back</button>
                                        </Modal.Footer>
                                    </Modal>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
 
export default EnterpriseLogin;