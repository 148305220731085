import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    //scrollToTop
    setTimeout(function(){ window.scrollTo(0, 0); }, 300);

    //bg image load
    var sections = document.querySelectorAll('main > section');
    for(var i = 0; i < sections.length; i++)
    {
      const style = window.getComputedStyle(sections[i], false);
      const bi = style.backgroundImage.slice(4, -1).replace(/"/g, "");
      if(bi !== '') {
        const a = i;

        const imageUrl = bi;
        let preloaderImg = document.createElement("img");
        preloaderImg.setAttribute("id", 'img'+i);
        preloaderImg.src = imageUrl;

        preloaderImg.addEventListener('load', (event) => {
            sections[a].classList.add("bg-loaded");
            preloaderImg = null;
        });
      }
    }


    

  }, [pathname]);

  return null;
}