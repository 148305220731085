import React , {useState} from 'react';
import { Container, Modal, Form, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
/* import ReCAPTCHA from "react-google-recaptcha"; */
import baseURL from '../Config';
import AthenaSaaSPrivacyPolicy from './AthenaSaaSPrivacyPolicy';
import AthenaSaaSTermsOfUse from './AthenaSaaSTermsOfUse';

function SiteBanner() {

  const [showSignupModal, setShowSignupModal] = useState(false);
  const handleCloseSignupModal = () => {localStorage.clear();setShowSignupModal(false);}
  const handleShowSignupModal = () => setShowSignupModal(true);

  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const handleCloseTermsOfUse = () => setShowTermsOfUse(false);
  const handleShowTermsOfUse = () => setShowTermsOfUse(true);
  
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const handleClosePrivacyPolicy = () => setShowPrivacyPolicy(false);
  const handleShowPrivacyPolicy = () => setShowPrivacyPolicy(true);

  const [showTermsOfUseMarketplace, setShowTermsOfUseMarketplace] = useState(false);
    const handleCloseTermsOfUseMarketplace = () => setShowTermsOfUseMarketplace(false);
    const handleShowTermsOfUseMarketplace = () => setShowTermsOfUseMarketplace(true);

    const [showPrivacyPolicyMarketplace, setShowPrivacyPolicyMarketplace] = useState(false);
    const handleClosePrivacyPolicyMarketplace = () => setShowPrivacyPolicyMarketplace(false);
    const handleShowPrivacyPolicyMarketplace = () => setShowPrivacyPolicyMarketplace(true);

    /*form */
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [formMessage, setformMessage] = useState("");
    const [jobprofile, setjobProfile] = useState("");
    const [industry, setIndustry] = useState("");
    const [country, setCountry] = useState("");

    const handleChangeJob = (e) => {
      setjobProfile(e.target.value);
    }

    const handleChangeIndustry = (e) => { 
      setIndustry(e.target.value);
    }

    const handleChangeCountry = (e) => { 
      setCountry(e.target.value);
    }

    const handleRequestSignupSubmit = async (e) => {
      e.preventDefault();
      try {
        if (typeof email !== "undefined") {
          var pattern = new RegExp(/^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)(?!live.com)(?!msn.com)(?!bigpond.net.au)(?!aim.com)(?!neuf.fr)(?!inbox.com)(?!rediffmail.com)(?!mailinator.com)(?!yandex.com)(?!in.com)(?!gmx.com)(?!gawab.com)(?!comcast.net)(?!bigpond.com)(?!terra.com)(?!mail.ru)(?!aim.com)(?!ig.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/i);
          if (!pattern.test(email)) {
            setformMessage("Enter a valid business email");
          }
        }

        var signupformdata = JSON.stringify({name:name,email: email,phone: phone,jobprofile: jobprofile,company: company,industry:industry,country:country});
        if(localStorage.hasOwnProperty("buttoncontext")){
          var context = "AthenaGPT, "+document.querySelector("title").innerText+", Button Context:"+localStorage.getItem("buttoncontext");
        }else{
          var context = "AthenaGPT, "+document.querySelector("title").innerText;
        }
        let res = await fetch(baseURL+"formdata", {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
          body:JSON.stringify({
            "application":"Athena",
            "context":context,
            "data":signupformdata
        })
        });
        if (res.status === 200) {
          localStorage.clear();
          setName("");
          setEmail("");
          setPhone("");
          setCompany("");
          setjobProfile("");
          setIndustry("");
          setCountry("");
          setformMessage("Thank you for your interest. We have received your request and one of our team member will connect with you soon.");
          setTimeout(function(){
            handleCloseSignupModal();
            setformMessage("");
        },3000);
        } else {
          setformMessage("Some error occured");
        }
      } catch (err) {
        console.log(err);
      }
    }
  /*form*/
  
  return (
    <div id="site-banner" className='site-banner'>
      <Container fluid>
        <div className="d-flex justify-content-center">
          <button className="btn-yellow" id="Athena_Signup_Form" onClick={handleShowSignupModal}>
            <span className='new'>New</span>
            <span>Introducing AthenaGPT &ndash; the first ever intelligence copilot for business teams. </span>
            <span className='link-text'>Sign-up now &#8594;</span>
          </button>
        </div>
        <Modal show={showSignupModal} onHide={handleCloseSignupModal} backdrop="static" keyboard={false} centered size="md">
          <Form className="mt-3" onSubmit={handleRequestSignupSubmit} id="Athena_Signup">
            <Modal.Header closeButton>
                <Modal.Title>
                  AthenaGPT <OverlayTrigger placement="bottom" overlay={<Tooltip className="modal-tooltip">Currently underdevelopment and training, AthenaGPT, will be a series of large language models, purpose-built from scratch with industry and domain knowledge for analytics, and designed to serve business users with intelligence, insights, and answers to critical business questions.</Tooltip>}>
                    <button type="cancel" className='btn-info'>i</button>
                  </OverlayTrigger>
                </Modal.Title>            
            </Modal.Header>
            <Modal.Body className="col-lg-12 requestdemo">
                <Form.Text>Sign-up to learn more about AthenaGPT, an intelligence co-pilot for business users.</Form.Text>   
                <Form.Group className="mb-3 mt-4 formitem">
                <Form.Control type="text" placeholder='Name *' required value={name} onChange={(e) => setName(e.target.value)} className="rounded-0 border-top-0 border-right-0 border-left-0" />
                </Form.Group>
                <Form.Group className="mb-3 mt-4 formitem">
                <Form.Control type="email"  placeholder='Business Email *' required value={email} onChange={(e) => setEmail(e.target.value)} className="rounded-0 border-top-0 border-right-0 border-left-0"/>
                </Form.Group>
                <Form.Group className="mb-3 mt-4 formitem">
                <Form.Control type="tel" placeholder="Phone *" value={phone} onChange={(e) => setPhone(e.target.value)} required className="rounded-0 border-top-0 border-right-0 border-left-0"/>
                </Form.Group>
                <Form.Group className="mb-3 mt-4 formitem">
                <Form.Select aria-label="Default select example" className="rounded-0" value={jobprofile} onChange={handleChangeJob}>
                  <option>Job Level</option>
                  <option value="CXO">CXO</option>
                  <option value="VP">VP</option>
                  <option value="Director">Director</option>
                  <option value="Director">Manager</option>
                  <option value="Director">Individual Contributor</option>
                  <option value="Director">Partner</option>
                  <option value="Student">Student</option>
                  <option value="Student">Other</option>
                </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 mt-4 formitem">
                <Form.Control type="text" placeholder="Company Name *" required value={company} onChange={(e) => setCompany(e.target.value)}  className="rounded-0 border-top-0 border-right-0 border-left-0"/>
                </Form.Group>
                <Form.Group className="mb-3 mt-4 formitem">
                <Form.Select aria-label="Default select example" className="rounded-0" value={industry} onChange={handleChangeIndustry} required>
                  <option>Industry</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Apparel">Apparel</option>
                  <option value="Banking">Banking</option>
                  <option value="Biotehnology">Biotehnology</option>
                  <option value="Chemicals">Chemicals</option>
                  <option value="Communiations">Communiations</option>
                  <option value="Construction">Construction</option>
                  <option value="Consulting">Consulting</option>
                  <option value="Education">Education</option>
                  <option value="Electronics">Electronics</option>
                  <option value="Energy">Energy</option>
                  <option value="Engineering">Engineering</option>
                  <option value="Entertainment">Entertainment</option>
                  <option value="Environmental">Environmental</option>
                  <option value="Financial Services">Financial Services</option>
                  <option value="Food &amp; Beverages">Food &amp; Beverages</option>
                  <option value="Government">Government</option>
                  <option value="Healthare">Healthare</option>
                  <option value="Hospitality">Hospitality</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Manufacturing">Machinery</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Media">Media</option>
                  <option value="Not for Profit">Not for Profit</option>
                  <option value="Other">Other</option>
                  <option value="Recreation">Recreation</option>
                  <option value="Retail">Retail</option>
                  <option value="Shipping">Shipping</option>
                  <option value="Technology">Technology</option>
                  <option value="Telecommunications">Telecommunications</option>
                  <option value="Transportation">Transportation</option>
                  <option value="Utilities">Utilities</option>
                </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 mt-4 formitem">
                <Form.Select aria-label="Default select example" className="rounded-0" value={country} onChange={handleChangeCountry}>
                  <option>Country</option>
                  <option value="Afganistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bonaire">Bonaire</option>
                  <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Canary Islands">Canary Islands</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">Central African Republic</option>
                  <option value="Chad">Chad</option>
                  <option value="Channel Islands">Channel Islands</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos Island">Cocos Island</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote DIvoire">Cote DIvoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaco">Curacao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Ter">French Southern Ter</option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Great Britain">Great Britain</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="India">India</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea North">Korea North</option>
                  <option value="Korea Sout">Korea South</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Midway Islands">Midway Islands</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Nambia">Nambia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherland Antilles">Netherland Antilles</option>
                  <option value="Netherlands">Netherlands (Holland, Europe)</option>
                  <option value="Nevis">Nevis</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau Island">Palau Island</option>
                  <option value="Palestine">Palestine</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Phillipines">Philippines</option>
                  <option value="Pitcairn Island">Pitcairn Island</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Republic of Montenegro">Republic of Montenegro</option>
                  <option value="Republic of Serbia">Republic of Serbia</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="St Barthelemy">St Barthelemy</option>
                  <option value="St Eustatius">St Eustatius</option>
                  <option value="St Helena">St Helena</option>
                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                  <option value="St Lucia">St Lucia</option>
                  <option value="St Maarten">St Maarten</option>
                  <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                  <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                  <option value="Saipan">Saipan</option>
                  <option value="Samoa">Samoa</option>
                  <option value="Samoa American">Samoa American</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Tahiti">Tahiti</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Erimates">United Arab Emirates</option>
                  <option value="United States of America">United States of America</option>
                  <option value="Uraguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City State">Vatican City State</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                  <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                  <option value="Wake Island">Wake Island</option>
                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zaire">Zaire</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                  </Form.Select>
                </Form.Group>
              <Form.Text>Your use of this website is subject to the Athena Analytics <a href="javascript:void(0);" className='txt-orange' onClick={handleShowTermsOfUse}>Terms of Use</a> &amp; <a href="javascript:void(0);" className='txt-orange' onClick={handleShowPrivacyPolicy}>Privacy Policy</a>.</Form.Text>
              <div className="message mt-3 mb-3">{formMessage ? <p>{formMessage}</p> : null}</div>
              {/* <ReCAPTCHA sitekey="6LeCPBgpAAAAAFsph2-8m4LaSbCRJxOIl0DeUiv0" onChange={() => setCaptchaVerified(true)} /> */}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" className="rounded-0" type='cancel' onClick={handleCloseSignupModal}>Cancel</Button>					
              <Button variant="primary" className="rounded-0 border-0" type='submit' id='Athena_Signup_Request_Submitted'/*  disabled={!captchaVerified} */>Submit</Button>																									  
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal centered show={showTermsOfUse} onHide={handleCloseTermsOfUse} backdrop="static" keyboard={false} className="terms-policies">
            <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body><AthenaSaaSTermsOfUse /></Modal.Body>
            <Modal.Footer>
                <button onClick={handleCloseTermsOfUse}>Back</button>
            </Modal.Footer>
        </Modal>
        <Modal centered show={showPrivacyPolicy} onHide={handleClosePrivacyPolicy} backdrop="static" keyboard={false} className="terms-policies">
            <Modal.Header closeButton>
                <Modal.Title>Privacy Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body><AthenaSaaSPrivacyPolicy /></Modal.Body>
            <Modal.Footer>
                <button onClick={handleClosePrivacyPolicy}>Back</button>
            </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default SiteBanner;