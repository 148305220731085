import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import Header from '../components/header';
import Footer from '../components/footer';
import StickyIcons from '../components/stickyIcons';
import PagetTitle from "../components/PageTitle";

function Care() {
    return (
        <>
            <Header isentrera={true} isacuverse={true} isdoorway={true}/>
            <PagetTitle title="Athena | Care"/>
            <main>
                <section className="care bg" style={{ backgroundImage: `url(${require("./../assets/images/care-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h1>CARE</h1>
                            <h3>One step into the future</h3>
                            <p><span className="txt-orange">We have our one foot in the market and one foot in the future to learn from innovative business models and emerging technologies, to unlock growth opportunities for our clients</span></p>
                        </div>
                    </Container>
                </section>
                <section className="care-emerging-tech">
                    <Container fluid>
                        <div className="section-title">
                            <h2>10X GROWTH</h2>
                            <h3>We Build The Future</h3>
                        </div>
                        <Row xs={1} sm={2}>
                            <Col>
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/potential.png')} width="90" alt="Potential" /></span>
                                    <p>We unlock our client's data potential to create new revenue models and enable them to participate in marketplaces as a provider of data and insight services.</p>
                                </div>
                            </Col>
                            <Col>
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/invest.png')} width="90" alt="invest" /></span>
                                    <p>We invest in emerging technologies to help our clients keep pace with the markets and the competition, to help them manage change, at no additional cost.</p>
                                </div>
                            </Col>
                            <Col>
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/clients.png')} width="100" alt="clients" /></span>
                                    <p>We help our clients learn emerging business practices, measure risks, develop new policies and products, and control business performance, to quickly adapt to the future.</p>
                                </div>
                            </Col>
                            <Col>
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/clients-assess.png')} width="90" alt="clients-assess" /></span>
                                    <p>Our clients can assess business readiness with a roadmap to optimize the cost of doing business and maximize revenues, to be data-driven and future ready.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="spdm">
                    <Container fluid>
                        <div className="section-title">
                            <h2>PLATFORM DELIVERY MODEL</h2>
                            <h3>Secure, Seamless, Scalable And Sustainable</h3>
                        </div>
                        <div className="text-center">
                            {/* <img src={require('./../assets/images/spdm.png')} className="img-fluid" alt="Secure, Seamless, Scalable And Sustainable" /> */}
                            <video width="100%" height="500" controls muted loop autoPlay>
                                <source src={require('./../assets/videos/YourDataBelongsToYou.mp4')} type="video/mp4"/>
                                Your browser does not support HTML video.
                            </video>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer />
            <StickyIcons isEntrera={true} isAcuverse={true} isDoorway={true} />
        </>
    );
}
 
export default Care;