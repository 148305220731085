import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Modal, Button, Carousel,Form} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import StickyIcons from '../components/stickyIcons';
import PagetTitle from "../components/PageTitle";
import baseURL from '../Config';
import enterprisePdf from './../assets/Entrera for Enterprises.pdf'
import investorsPdf from './../assets/Entrera for Investors.pdf'
import EntreraPrivacyPolicy from '../components/EntreraPrivacyPolicy';
import EntreraTermsOfUse from '../components/EntreraTermsOfUse';

function Entrera() {
    const [showCSuite, setShowCSuite] = useState(false);
    const handleCloseCSuite = () => {setShowCSuite(false);setMessage("");}
    const handleShowCSuite = () => setShowCSuite(true);

    const [showEnterprises, setShowEnterprises] = useState(false);
    const handleCloseEnterprises = () => {setShowEnterprises(false);setMessage("");}
    const handleShowEnterprises = () => setShowEnterprises(true);

    const [showInvestors, setShowInvestors] = useState(false);
    const handleCloseInvestors = () => {setShowInvestors(false);setMessage("");}
    const handleShowInvestors = () => setShowInvestors(true);

    const [showTermsOfUse, setShowTermsOfUse] = useState(false);
    const handleCloseTermsOfUse = () => setShowTermsOfUse(false);
    const handleShowTermsOfUse = () => setShowTermsOfUse(true);

    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const handleClosePrivacyPolicy = () => setShowPrivacyPolicy(false);
    const handleShowPrivacyPolicy = () => setShowPrivacyPolicy(true);

    const [videoShow, setVideoShow] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if(searchParams.get("entrera_privacy_policy") === 'true') {
            setShowPrivacyPolicy(true);
        } else {
            setShowPrivacyPolicy(false);
        }

        if(searchParams.get("entrera_terms_of_use") === 'true') {
            setShowTermsOfUse(true);
        } else {
            setShowTermsOfUse(false);
        }
    },[]);

     /*form submit*/
     const[requestgroups, setRequestGroups] = useState([]);
     const [other, setOther] = useState("");
     const [email, setEmail] = useState("");
     const [phoneNumber, setPhoneNumber] = useState("");
     const [message, setMessage] = useState("");

     let handleInputChange = (e) => {
         const target = e.target;
         var value = target.value;
         target.checked ? setRequestGroups(arr => [...arr,value]) : setRequestGroups.slice(value,1);
     }

     /*form submit*/
     const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (typeof email !== "undefined") {
                var pattern = new RegExp(/^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)(?!live.com)(?!msn.com)(?!bigpond.net.au)(?!aim.com)(?!neuf.fr)(?!inbox.com)(?!rediffmail.com)(?!mailinator.com)(?!yandex.com)(?!in.com)(?!gmx.com)(?!gawab.com)(?!comcast.net)(?!bigpond.com)(?!terra.com)(?!mail.ru)(?!aim.com)(?!ig.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/i);
                if (!pattern.test(email)) {
                    setMessage("Enter a valid business email");
                }
              }
           var formdata = JSON.stringify({requestgroups:requestgroups,other: other,email: email,phoneNumber: phoneNumber});
           var context = "Athena Enterprises, "+document.querySelector("title").innerText;
               let res = await fetch(baseURL+"formdata", {
                   method: "POST",
                   headers: {
                       "Content-Type": "application/json; charset=UTF-8",
               },
             body:JSON.stringify({
               "application":"Athena",
               "context":context,
               "data":formdata
           })
               
          });
                                         
          if (res.status === 200) {
            setRequestGroups("");
            setOther("");
            setEmail("");
            setPhoneNumber("");
            setMessage("Thank you for your interest. We have received your request and one of our team member will connect with you soon.");
            setTimeout(function(){
                handleCloseCSuite();
                handleCloseEnterprises();
                handleCloseInvestors();
            },3000);
          } else {
            setMessage("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }
      };
    /*form submit*/

    return (
        <>
            <Header isacuverse={true} isdoorway={true}/>
            <PagetTitle title="Athena | Care | Entrera"/>
            <main>
                <section className="entrera bg" style={{ backgroundImage: `url(${require("./../assets/images/entrera-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h1>ENTRERA</h1>
                            <h3>Where Will You Be By 2025?</h3>
                            <p>80% businesses will fail by 2025; take this assessment to find out how ready your enterprise is</p>
                        </div>
                        <Row>
                            <Col lg={5} className="enterprise">
                                <div className="text-center"><img src={require('./../assets/images/entrera-E.png')} alt="Entrera E" /></div>
                                <p>Enterprises undertake different approaches towards building a data-driven culture. Yet, only 24.0% of Fortune 1000 organizations have created a data-driven organization. 92.2% of Fortune 1000s claim culture as the biggest impediment to becoming a data-driven organization.</p>
                                <div className='entrera-links-container'>
                                    <a href="https://care.risingahead.com/index.php?r=survey/index&sid=582789&lang=en" target="_blank" className="btn-yellow" id="entrera_sa">SELF ASSESS</a>
                                    <span className='txt-orange'>
                                        <a href="javascript:void(0);" className='me-2 txt-orange' onClick={handleShowPrivacyPolicy}>Privacy Policy</a> |
                                        <a href="javascript:void(0);" className='ms-2 txt-orange' onClick={handleShowTermsOfUse}>Terms of Use</a>
                                    </span>
                                    <Modal centered show={showTermsOfUse} onHide={handleCloseTermsOfUse} backdrop="static" keyboard={false} className="terms-policies">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Terms and Conditions</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body><EntreraTermsOfUse /></Modal.Body>
                                        <Modal.Footer>
                                            <button onClick={handleCloseTermsOfUse}>Back</button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal centered show={showPrivacyPolicy} onHide={handleClosePrivacyPolicy} backdrop="static" keyboard={false} className="terms-policies">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Privacy Policy</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body><EntreraPrivacyPolicy /></Modal.Body>
                                        <Modal.Footer>
                                            <button onClick={handleClosePrivacyPolicy}>Back</button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </Col>
                            <Col lg={{span: 4, offset: 2}} className="interpret-results">
                                <div className="image"><img src={require('./../assets/images/enterprise-readiness.png')} width="380" height="469" alt="Results" className="img-responsive" /></div>
                                <div><a href="#interpret" className="btn-yellow">INTERPRET RESULTS</a></div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="data-culture video-bg bg" style={{ backgroundImage: `url(${require("./../assets/images/videoBG-Entrera.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>ADAPT THE FUTURE</h2>
                            <p><span className="txt-orange"><a href="https://www.linkedin.com/posts/risingahead_adapt-the-future-time-is-now-activity-6809934392471900160-q16p?utm_source=linkedin_share&utm_medium=member_desktop_web" target="_blank">Markets are experiencing a rising gulf size gap in capabilities and business readiness.</a></span></p>
							<Row className="video">
                                <Col xs={12} lg={4}>
                                    <button type="button" id="Entrera_80%_of_businesses_will_fail_by_2025" className="video-text" onClick={() => setVideoShow("show")}>
                                        <span className="image"><img id="icon_Entrera_80%_of_businesses_will_fail_by_2025" src={require('./../assets/images/video.png')} alt="Video" /></span>
                                        <span className="text" id="text_Entrera_80%_of_businesses_will_fail_by_2025">80% of businesses will fail by 2025</span>
                                    </button>
                                </Col>
                                <Col xs={12} lg={8} xl={5} className={"video-iframe " + videoShow}>
                                    <iframe width="560" height="380" src="https://www.youtube.com/embed/tPLdznzRNyU?controls=0&rel=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </Col>
                            </Row>
						</div>
                    </Container>
                </section>
                <section className="data-culture">
                    <Container fluid>
						<div className="section-title">
							<h2>ENTERPRISE READINESS ASSESSMENT</h2>
                            <h3>Who This Serves</h3>
                            <p>Recognize your business challenges and what measures you can undertake today to transform your business.</p>
                        </div>
                        <Row xs={1} md={2} xl={3} className="justify-content-center">
                            <Col className="item">
                                <div className="inner">
                                    <h4>C-SUITE</h4>
                                    <div className="image">
                                        <img src={require('./../assets/images/c-suite.png')} alt="C-SUITE" className="img-responsive" />
                                        <span className="overlay"></span>
                                        <div className="details">
                                            <p>C-suite individuals or business leaders or heads of functions can take this free self-assessment to determine the root-cause(s) of their organization's failure to adapt, to harness the potential of data and deliver business impact.</p>
                                            <div className="buttons">
                                               <a href="https://care.risingahead.com/index.php?r=survey/index&sid=582789&lang=en" target="_blank" className="btn-yellow" id="entrera_ind_sa"><img src={require('./../assets/images/self-assess-icon.png')} alt="Self Assess" /> Self Assess</a>
                                                <button type="button" id="Entrera_C-suite_Form" className="btn-yellow connect" onClick={handleShowCSuite}><img id="icon_Entrera_C-suite_Form" src={require('./../assets/images/connect-icon.png')} alt="Connect" /> Connect</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal show={showCSuite} onHide={handleCloseCSuite} backdrop="static" keyboard={false} centered size="lg" >
                                <Form id="Entrera_C-suite" className="mt-3" onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>C-SUITE Members</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="col-lg-6">
                                    <p>I want to request a call to:</p>
                                            <Form.Group>
                                                <Form.Check type="checkbox" name="requestgroup"  value="Interpret Results" onChange={handleInputChange} label="Interpret Results"/>
                                                <Form.Check type="checkbox" name="requestgroup"  value="sign up enterprise" onChange={handleInputChange} label="Sign-up Enterprise"/>
                                                <Form.Check type="checkbox" name="requestgroup" value="Other" onChange={handleInputChange} label="Other"/>
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
                                            <Form.Label>If others please specify:</Form.Label>
                                            <Form.Control type="text" placeholder="" value={other} onChange={(e) => setOther(e.target.value)} className="rounded-0 border-top-0 border-right-0 border-left-0" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4"  controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email address:</Form.Label>
                                            <Form.Control type="email" placeholder="" required value={email} onChange={(e) => setEmail(e.target.value)} className="rounded-0 border-top-0 border-right-0 border-left-0"/>
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4">
                                            <Form.Label>Phone Number:</Form.Label>
                                            <Form.Control type="tel" value={phoneNumber} required onChange={(e) => setPhoneNumber(e.target.value)} className="rounded-0 border-right-0"/>
                                            </Form.Group>
                                            <Form.Text>Your use of this website is subject to the Entrera <a href="javascript:void(0);" className='txt-orange' onClick={handleShowTermsOfUse}>Terms of Use</a> &amp; <a href="javascript:void(0);" className='txt-orange' onClick={handleShowPrivacyPolicy}>Privacy Policy</a>.</Form.Text>
                                            <div className="message">{message ? <p>{message}</p> : null}</div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" className="rounded-0 border-0" type='submit' id='Entrera_C-suite_Form_Submitted'>Submit</Button>																									  
                                    </Modal.Footer>
                                    </Form>
                                </Modal>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <h4>ENTERPRISE</h4>
                                    <div className="image">
                                        <img src={require('./../assets/images/enterprise.png')} alt="ENTERPRISE" className="img-responsive" />
                                        <span className="overlay"></span>
                                        <div className="details">
                                            <p>Enterprises or companies can select multiple cross functional stakeholders to undertake this self-assessment, to determine the collective cause of their organization's failure to transform, and discover functional alignment challenges.</p>
                                            <div className="buttons">
                                                <a href={enterprisePdf} download="Entrera for Enterprises.pdf" className="btn-yellow" id="entrera_ent_km"><img src={require('./../assets/images/self-assess-icon.png')} alt="Know More" /> Know More</a>
                                                <button type="button" id="Entrera_ENT_Form" className="btn-yellow connect" onClick={handleShowEnterprises}><img id="icon_Entrera_ENT_Form" src={require('./../assets/images/connect-icon.png')} alt="Connect" /> Connect</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal show={showEnterprises} onHide={handleCloseEnterprises} backdrop="static" keyboard={false} centered size="lg">
                                <Form id="Entrera_ENT" className="mt-3" onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Enterprises</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="col-lg-6">
                                    <p>I want to request a call to:</p>
                                            <Form.Group>
                                                <Form.Check type="checkbox" name="requestgroup"  value="Enterprise Sign-up" label="Enterprise Sign-up"/>
                                                <Form.Check type="checkbox" name="requestgroup"  value="Investor Sign-up" label="Investor Sign-up"/>
                                                <Form.Check type="checkbox" name="requestgroup" value="Other" label="Other"/>
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
                                            <Form.Label>If other, please specify:</Form.Label>
                                            <Form.Control type="text" placeholder="" value={other} onChange={(e) => setOther(e.target.value)} className="rounded-0 border-top-0 border-right-0 border-left-0" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4"  controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email address:</Form.Label>
                                            <Form.Control type="email" placeholder="" required value={email} onChange={(e) => setEmail(e.target.value)} className="rounded-0 border-top-0 border-right-0 border-left-0"/>
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4">
                                            <Form.Label>Phone Number:</Form.Label>
                                            <Form.Control type="tel" value={phoneNumber} required onChange={(e) => setPhoneNumber(e.target.value)} className="rounded-0 border-right-0"/>
                                            </Form.Group>
                                            <Form.Text>Your use of this website is subject to the Entrera <a href="javascript:void(0);" className='txt-orange' onClick={handleShowTermsOfUse}>Terms of Use</a> &amp; <a href="javascript:void(0);" className='txt-orange' onClick={handleShowPrivacyPolicy}>Privacy Policy</a>.</Form.Text>
                                            <div className="message">{message ? <p>{message}</p> : null}</div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" className="rounded-0 border-0" type='submit' id='Entrera_ENT_Form_Submitted'>Submit</Button>																											  
                                    </Modal.Footer>
                                    </Form>
                                </Modal>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <h4>INVESTOR</h4>
                                    <div className="image">
                                        <img src={require('./../assets/images/investor.png')} alt="INVESTOR" className="img-responsive" />
                                        <span className="overlay"></span>
                                        <div className="details">
                                            <p>Investors can select multiple enterprises on their portfolio to undertake this self-assessment, to determine the cultural readiness and the growth barriers of their portfolio companies to deliver the targeted returns on investment.</p>
                                            <div className="buttons">
                                                <a href={investorsPdf} download="Entrera for Investors.pdf" className="btn-yellow" id="entrera_inv_km"><img src={require('./../assets/images/self-assess-icon.png')} alt="Know More" /> Know More</a>
                                                <button type="button" id="Entrera_INV_Form" className="btn-yellow connect" onClick={handleShowInvestors}><img id="icon_Entrera_INV_Form" src={require('./../assets/images/connect-icon.png')} alt="Connect" /> Connect</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal show={showInvestors} onHide={handleCloseInvestors} backdrop="static" keyboard={false} centered size="lg">
                                <Form id="Entrera_INV" className="mt-3" onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Investors</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="col-lg-6">
                                    <p>I want to request a call to:</p>
                                            <Form.Group>
                                            <Form.Check type="checkbox" name="requestgroup"  value="Enterprise Sign-up" label="Enterprise Sign-up"/>
                                                <Form.Check type="checkbox" name="requestgroup"  value="Investor Sign-up" label="Investor Sign-up"/>
                                                <Form.Check type="checkbox" name="requestgroup" value="Other" label="Other"/>
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
                                            <Form.Label>If other, please specify:</Form.Label>
                                            <Form.Control type="text" placeholder="" value={other} onChange={(e) => setOther(e.target.value)} className="rounded-0 border-top-0 border-right-0 border-left-0" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4"  controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email address:</Form.Label>
                                            <Form.Control type="email" placeholder="" required value={email} onChange={(e) => setEmail(e.target.value)} className="rounded-0 border-top-0 border-right-0 border-left-0"/>
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4">
                                            <Form.Label>Phone Number:</Form.Label>
                                            <Form.Control type="tel" value={phoneNumber} required onChange={(e) => setPhoneNumber(e.target.value)} className="rounded-0 border-right-0"/>
                                            </Form.Group>
                                            <Form.Text>Your use of this website is subject to the Entrera <a href="javascript:void(0);" className='txt-orange' onClick={handleShowTermsOfUse}>Terms of Use</a> &amp; <a href="javascript:void(0);" className='txt-orange' onClick={handleShowPrivacyPolicy}>Privacy Policy</a>.</Form.Text>
                                            <div className="message">{message ? <p>{message}</p> : null}</div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" className="rounded-0 border-0" type='submit' id='Entrera_INV_Form_Submitted'>Submit</Button>																										  
                                    </Modal.Footer>
                                    </Form>
                                    </Modal>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="approach">
                    <Container fluid>
                        <div className="section-title">
                            <h2>ENTRERA</h2>
                            <h3>Your Data And Analytics Approach</h3>
                            <p><span className="txt-orange">"80% of businesses will fail by 2025 if they continue to take the same approach" ~ Gartner</span></p>
                            
                        </div>
                        <Row xs={1} md={2} xl={3}>
                            <Col className="item">
                                <div className="inner">
                                    <h4>CENTRALIZED</h4>
                                    <div className="image"><img src={require('./../assets/images/centralized.png')} alt="CENTRALIZED" className="img-responsive" /></div>
                                    <ul>
                                        <li>Internal, stand-alone, performance driven unit</li>
                                        <li>Cross-functional capability development model</li>
                                        <li>Diverse expertise and skill-sets</li>
                                        <li>Data and service delivery governance</li>
                                        <li>Standard products, tools and analytics technologies</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <h4>SELF SERVICE</h4>
                                    <div className="image"><img src={require('./../assets/images/self-service.png')} alt="SELF SERVICE" className="img-responsive" /></div>
                                    <ul>
                                        <li>Business function ownership</li>
                                        <li>Business prioritization and validation</li>
                                        <li>Domain specific analytics solutions</li>
                                        <li>Business budgets and project spend</li>
                                        <li>Multiple products, tools, and technologies</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <h4>HYBRID</h4>
                                    <div className="image"><img src={require('./../assets/images/hybrid.png')} alt="HYBRID" className="img-responsive" /></div>
                                    <ul>
                                        <li>Business partner model, co-ownership</li>
                                        <li>Business prioritization and analytics governance</li>
                                        <li>Data, service and solution delivery governance</li>
                                        <li>Coordinated procurement, budget planning</li>
                                        <li>Standard products, tools and analytics technologies</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-bottom">No matter which approach, efficiency, productivity, performance and growth must be the success indicators</div>
                    </Container>
                </section>
                <section className="entrera-details">
                    <Container fluid>
                        <Row className="align-items-md-center">
                            <Col lg={5} xl={5} className="text-center">
                                <img src={require('./../assets/images/entrera-logo.png')} alt="Entrera" width="500" className="entrera-logo img-responsive" />
                            </Col>
                            <Col lg={7} xl={{ span: 6, offset: 1 }}>
                                <ul>
                                    <li>
                                        <h4>
                                            <span className="image"><img src={require('./../assets/images/data-quality.png')} alt="DATA QUALITY" /></span>
                                            <span className="text">DATA <br/>QUALITY</span>
                                        </h4>
                                        <p>Harness the potential of the data you have right now and the new data that comes in every day. Integrating the latest data as well as non-standard data is key. Entrera<sup>TM</sup> identifies the data gaps and recommends the best data integration approach with the right data to support decision-making.</p>
                                    </li>
                                    <li>
                                        <h4>
                                            <span className="image"><img src={require('./../assets/images/process-digitalization.png')} alt="PROCESS DIGITALIZATION" /></span>
                                            <span className="text">PROCESS <br/>DIGITALIZATION</span>
                                        </h4>
                                        <p>Systems need to be self-learning, processes need to be robust enough and detailed enough to rely on the technology to identify the problem, communicate the issue and recommend a change. Standardize key business metrics across the organization and categorize solutions to avoid overlap.</p>
                                    </li>
                                    <li>
                                        <h4>
                                            <span className="image"><img src={require('./../assets/images/decision-intelligence.png')} alt="DECISION INTELLIGENCE" /></span>
                                            <span className="text">DECISION <br/>INTELLIGENCE</span>
                                        </h4>
                                        <p>Provides the ability to deliver actionable insights and intelligence that builds stakeholder confidence. Allows the enterprise to be externally focused with the right intelligence to give your customer what they want, when they want it. Entrera<sup>TM</sup> provides a framework to create, satisfy, and retain more customers.</p>
                                    </li>
                                    <li>
                                        <h4>
                                            <span className="image"><img src={require('./../assets/images/tech-adoption.png')} alt="TECH ADOPTION" /></span>
                                            <span className="text">TECHNOLOGY <br/>ADOPTION</span>
                                        </h4>
                                        <p>Studies and aligns the unified vision of an enterprise. 92% of the top 1000 Fortune companies claim culture is the biggest impediment to successfully transforming into a data-driven enterprise. Companies need to future-proof their business and rely on new technologies.</p>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="interpret-your-results">
                    <Container fluid>
                        <div className="section-title">
                            <h2 id="interpret">INTERPRET YOUR RESULTS</h2>
                            <h3>Enterprise Readiness Assessment</h3>
                            <p>Business leaders will get a comprehensive 3-page root-cause analysis of the failure to adapt, to transform, and next steps to strengthen build a data driven culture.</p>
                        </div>
                        <Row xs={1} sm={3}>
                            <Col>
                                <h4>ENTERPRISE READINESS</h4>
                                <img src={require('./../assets/images/enterprise-readiness.png')} alt="ENTERPRISE READINESS" width="400" className="img-responsive" />
                                <div className="details">
                                    <h5>Enterprise Readiness Rating</h5>
                                    <p>2025 Readiness Prediction</p>
                                </div>
                            </Col>
                            <Col>
                                <h4>READINESS FOUNDATION</h4>
                                <img src={require('./../assets/images/readiness-foundation.png')} alt="READINESS FOUNDATION" width="400" className="img-responsive" />
                                <div className="details">
                                    <h5>Rating Rationale</h5>
                                    <p>Global Transformation Trends</p>
                                </div>
                            </Col>
                            <Col>
                                <h4>STRENGTHEN FOUNDATION</h4>
                                <img src={require('./../assets/images/strengthen-foundation.png')} alt="STRENGTHEN FOUNDATION" width="400" className="img-responsive" />
                                <div className="details">
                                    <h5>Data-driven Culture</h5>
                                    <p>Suggestive Next Steps</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="err">
                    <Container fluid>
                        <div className="section-title">
                            <h2>INTERPRET YOUR RESULTS</h2>
                            <h3>Your 2025 Readiness Prediction</h3>
                        </div>
                        <Carousel>
                            <Carousel.Item>
                                <img src={require('./../assets/images/err-slide-1.png')} alt="Enterprise Readiness Rank " className="img-responsive" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={require('./../assets/images/err-slide-2.png')} alt="Enterprise Readiness Rank " className="img-responsive" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={require('./../assets/images/err-slide-3.png')} alt="Enterprise Readiness Rank " className="img-responsive" />
                            </Carousel.Item>
                        </Carousel>
                    </Container>
                </section>
                <section className="interpret-your-results challenges">
                    <Container fluid>
                        <div className="section-title">
                            <h2>INTERPRET YOUR RESULTS</h2>
                            <h3>Challenges And Next Steps</h3>
                        </div>
                        <Row>
                            <Col lg={{span:4, offset:2}}>
                                <h4>READINESS FOUNDATION</h4>
                                <div className="inner">
                                    <img src={require('./../assets/images/readiness-foundation.png')} alt="READINESS FOUNDATION" width="400" className="img-responsive" />
                                    <span className="overlay"><span className="overlay-wrap">What are the root causes of your company's inability to become data-driven and deliver a bottom line impact? What are the global trends on adoption? How are businesses approaching these challenges?</span></span>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <h4>STRENGTHEN FOUNDATION</h4>
                                <div className="inner">
                                    <img src={require('./../assets/images/strengthen-foundation.png')} alt="STRENGTHEN FOUNDATION" width="400" className="img-responsive" />
                                    <span className="overlay"><span className="overlay-wrap">What are the potential next steps that you may undertake to start to resolve existing challenges? What should be the business transformation approach? How can you strengthen the foundation of your business?</span></span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
            <StickyIcons isAcuverse={true} isDoorway={true} />
        </>
    );
}
 
export default Entrera;