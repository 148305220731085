import React,{useEffect, useState} from 'react';
import { Container, Row, Col, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import './../assets/css/styles.css';
import SiteBanner from '../components/SiteBanner';
import Header from '../components/header';
import Footer from '../components/footer';
import StickyIcons from '../components/stickyIcons';
import IntroAnimation from '../components/IntroAnimation';
import logo from "./../assets/images/logo-large.png";
import splashlogo from "./../assets/images/splashlogo.png";
import Ticker from "../components/Ticker";

function Home() {

    const navigate = useNavigate();

    const [video1Show, setVideo1Show] = useState("");
    const [video2Show, setVideo2Show] = useState("");
    const [video3Show, setVideo3Show] = useState("");
    const [video4Show, setVideo4Show] = useState("");
    const [videoShow2, setVideoShow2] = useState("");
    const [coreCapabilities, setCoreCapabilities] = useState("Consumes Data");
    const [statementActive, setStatementActive] = useState("Your data belongs to you");

    const handleVideo1Show = () => {
        setVideo1Show("show");
        setVideo2Show("");
        setVideo3Show("");
        setVideo4Show("");
    }
    const handleVideo2Show = () => {
        setVideo2Show("show");
        setVideo1Show("");
        setVideo3Show("");
        setVideo4Show("");
    }
    const handleVideo3Show = () => {
        setVideo3Show("show");
        setVideo1Show("");
        setVideo2Show("");
        setVideo4Show("");
    }
    const handleVideo4Show = () => {
        setVideo4Show("show");
        setVideo1Show("");
        setVideo2Show("");
        setVideo3Show("");
    }

    const handleDemo = (event)=> {
        let requestdemo = document.getElementById('Athena_Demo_Form');
        localStorage.setItem('buttoncontext',event.target.dataset.context);
        requestdemo.click();
    }

    const capabilities = [
        {
            title: "Consumes Data",
            image: "consumes-data",
            text: "Limitless sources, limitless forms, and limitless volumes"
        },
        {
            title: "Analyzes Data",
            image: "analyses-data",
            text: "Performance, productivity, risks, profitability, and brand reputation"
        },
        {
            title: "Discovers Patterns",
            image: "discovers-patterns",
            text: "Consumer behavior, consumer churn, and return on investment"
        },
        {
            title: "Learns Patterns",
            image: "learns-patterns",
            text: "Monitors, controls, and predicts business performance, risks, and profitability"
        },
        {
            title: "Learns Market",
            image: "learns-market",
            text: "Changing consumer behaviors, evolving market conditions, and market opportunities"
        },
        {
            title: "Informs Insights",
            image: "autonomous",
            text: "Notifies, alerts, and answers critical questions on intelligence and insights to business users"
        },
        {
            title: "Makes Business Decisions",
            image: "makes-business-decision",
            text: "Adapts and executes business strategy, creates new market opportunities, and drives growth"
        }
    ];

    const handleCapabilitiesActive = (title) => {
        setCoreCapabilities(title);
    }

    const handlePrev = () => {
        var index;
        const list = [...document.querySelectorAll('.text-video-slider-wrapper .item')];
        const active = document.querySelector('.text-video-slider-wrapper .item.active');
        index = list.indexOf(active);
        //console.log(index, list.length);
        if(index === 0){
            setCoreCapabilities(capabilities[list.length-1].title);
        } else {
            setCoreCapabilities(capabilities[index-1].title);
        }

    }
    const handleNext = () => {
        var index;
        const list = [...document.querySelectorAll('.text-video-slider-wrapper .item')];
        const active = document.querySelector('.text-video-slider-wrapper .item.active');
        index = list.indexOf(active);
        //console.log(index, list.length);
        if(index === list.length-1){
            setCoreCapabilities(capabilities[0].title);
        } else {
            setCoreCapabilities(capabilities[index+1].title);
        }
    }

    const statements = [
        {
            text1: "Your data belongs to you",
            text2: "Competitive Advantage"
        },
        {
            text1: "Reduced cost of intelligence & decisions",
            text2: "Lower Op-ex"
        },
        {
            text1: "AI-driven recommendations & decisions",
            text2: "Higher Profitability"
        },
        {
            text1: "Personalized, real-time, intelligence assistance",
            text2: "Speed to Market"
        }
    ];

    const handleStatementsActive = (text1) => {
        setStatementActive(text1);
    }

    /* useEffect(() => {
        if(statements.length > 0){
            var index;
            const list = [...document.querySelectorAll('.statements li')];
            const active = document.querySelector('.statements li.active');
            index = list.indexOf(active);
            //console.log(index, list.length);
            setTimeout(() => {
                if(index === list.length-1){
                    setStatementActive(statements[0].text1);
                } else {
                    setStatementActive(statements[index+1].text1);
                }
            }, 20000);
        }
    },[statementActive]); */

    return (
        <>
            <SiteBanner />
            <Header isentrera={true} isacuverse={true} isdoorway={true}/>
            <main>
            <IntroAnimation splashlogo={splashlogo} imgsrc={logo}/>
                <section>
                    <Container fluid>
                        <div className="section-title">
                            <h2>GENERATIVE AI ANALYTICS PLATFORM</h2>
                            <h3>Athena is your personal intelligence analyst</h3>
                            <p>Make smarter business decisions, faster, in real-time, and at one-third the cost</p>
                        </div>
                        <Row xs={1} md={1} lg={3} className='mt-5'>
                            <Col lg={4} className='d-inline-flex flex-column justify-content-around'>
                                <ul className='ul-ticks'>
                                    <li>Generative AI</li>
                                    <li>Decision Engines</li>
                                    <li>Business Intelligence</li>
                                    <li>Autonomous Data Warehousing</li>
                                </ul>
                                <div className='mt-5'>
                                    <button type='button' className="btn-yellow" onClick={() => document.getElementById("product-tour").scrollIntoView()}>PRODUCT TOUR</button>
                                    <button type='button' className="btn-transparent ms-3 mt-2" onClick={handleDemo} data-context="Origination Trends">REQUEST DEMO</button>
                                </div>
                            </Col>
                            <Col lg={{span: 7, offset: 1}}>
                                <video width="100%" height="auto" controls muted loop autoPlay>
                                    <source src={require('./../assets/videos/AthenaGenAIAnalyticsPlatform.mp4')} type="video/mp4"/>
                                    Your browser does not support HTML video.
                                </video>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="bg section-banner" style={{ backgroundImage: `url(${require("./../assets/images/your-data-bg-1.jpg")})`}}>
                    <Container fluid>
                        <div className="section-title d-flex flex-column" style={{height: "100%"}}>
                            <h3 className='mb-4'><span className='txt-orange'>Your Data</span> Belongs To You</h3>
                            <p className='mt-auto'>Athena Analytics-as-a-Service Platform Lives In Your IT Environment</p>
                        </div>
                    </Container>
                </section>
                {/* <section className="bg section-banner align-items-center" style={{ backgroundImage: `url(${require("./../assets/images/your-data-bg-2.jpg")})`}}>
                    <Container fluid>
                        <div className="section-title text-center">
                            <h3 className='mb-4'><span className='txt-orange'>Your Data</span> Belongs To You</h3>
                            <p>Athena Analytics-as-a-Service Platform Lives In Your IT Environment</p>
                        </div>
                    </Container>
                </section> */}
                <section>
                    <Container fluid>
                        <div className="section-title">
                            <h2>OUR DIFFERENTIATOR</h2>
                            <h3>The only analytics platform that puts you in control of your data</h3>
                            <p>One-stop, plug-n-play, and real-time analytics platform that serve s intelligence and decisions needed to plan and execute your business strategy</p>
                        </div>
                        <div className="acuverse-details">
                            <Row xs={1} sm={2} lg={4}>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/DataSecurity.png')} width="70" alt="Data Protection" /></span>
                                            <span className="text">DATA<br/> PROTECTION</span>
                                        </div>
                                        <div className="flip-card-back bg-orange">
                                            <p>Data stays in your company's IT environment - on cloud or on premises - to protect and secure your data per your company's data protection and privacy policies.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/securverse.png')} width="70" alt="Reliability & Security" /></span>
                                            <span className="text">RELIABILITY<br/> &amp; SECURITY</span>
                                        </div>
                                        <div className="flip-card-back bg-orange">
                                            <p>Full control over data and infrastructure, and you decide who gets access to what data, intelligence, insights, engines, reports, and dashboards in your company.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/Product-Personalization.png')} width="70" alt="Frictionless Personalization" /></span>
                                            <span className="text">FRICTIONLESS<br/> PERSONALIZATION</span>
                                        </div>
                                        <div className="flip-card-back bg-orange">
                                            <p>Industry-ready, comprehensive, easily customizable, it's effortlessly personalized to serve the special needs of decision makers, across business functions.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/cost-optimization.png')} width="70" alt="Most Cost Effective Analytics" /></span>
                                            <span className="text">MOST COST <br/>EFFECTIVE ANALYTICS</span>
                                        </div>
                                        <div className="flip-card-back bg-orange">
                                            <p>Day-to-day platform management support, up grades, upkeep, and maintenance, for all business functions, on a Platform-as-a-Service subscription model.</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <section id="product-tour" className="core-capabilities" style={{paddingTop: "120px"}}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>CORE CAPABILITIES</h2>
                            <h3>Analytics-as-a-Service Platform</h3>
                            <p>The only analytics platform that informs business intelligence and insights, and makes business decisions, while living in your IT environment, securing, and protecting your data</p>
                        </div>
                        <div className='text-video-slider'>
                            <Row>
                                <Col className="item" lg={4}>
                                    <div className='text-video-slider-wrapper'>
                                        {capabilities.length > 0 && capabilities.map((item, index) => {
                                            return(<div key={index} className={coreCapabilities === item.title ? 'item active' : 'item'} onMouseEnter={() => handleCapabilitiesActive(item.title)}>
                                                <div className="image"><img src={require(`./../assets/images/${item.image}.png`)} alt={item.title} /></div>
                                                <div>
                                                    <span className='title'>{item.title}</span>
                                                    <p>{item.text}</p>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </Col>
                                <Col xs="auto" lg={{span: 7, offset: 1}} className="item">
                                    {coreCapabilities === 'Consumes Data' && <video width="100%" height="460" controls muted loop autoPlay>
                                        <source src={require('./../assets/videos/ConsumesData.mp4')} type="video/mp4"/>
                                        Your browser does not support HTML video.
                                    </video>}
                                    {coreCapabilities === 'Analyzes Data' && <video width="100%" height="460" controls muted loop autoPlay>
                                        <source src={require('./../assets/videos/AnalyzesData.mp4')} type="video/mp4"/>
                                        Your browser does not support HTML video.
                                    </video>}
                                    {coreCapabilities === 'Discovers Patterns' && <video width="100%" height="460" controls muted loop autoPlay>
                                        <source src={require('./../assets/videos/DiscoversPatterns.mp4')} type="video/mp4"/>
                                        Your browser does not support HTML video.
                                    </video>}
                                    {coreCapabilities === 'Learns Patterns' && <video width="100%" height="460" controls muted loop autoPlay>
                                        <source src={require('./../assets/videos/LearnsPatterns.mp4')} type="video/mp4"/>
                                        Your browser does not support HTML video.
                                    </video>}
                                    {coreCapabilities === 'Learns Market' && <video width="100%" height="460" controls muted loop autoPlay>
                                        <source src={require('./../assets/videos/LearnsMarkets.mp4')} type="video/mp4"/>
                                        Your browser does not support HTML video.
                                    </video>}
                                    {coreCapabilities === 'Makes Business Decisions' && <video width="100%" height="460" controls muted loop autoPlay>
                                        <source src={require('./../assets/videos/MakesDecisions.mp4')} type="video/mp4"/>
                                        Your browser does not support HTML video.
                                    </video>}
                                    {coreCapabilities === 'Informs Insights' && <video width="100%" height="460" controls muted loop autoPlay>
                                        <source src={require('./../assets/videos/InformsInsights.mp4')} type="video/mp4"/>
                                        Your browser does not support HTML video.
                                    </video>}
                                </Col>
                            </Row>
                            <Row className='navigation mt-5 justify-content-between'>
                                <Col lg="auto">
                                    <div className='btn-wrapper'>
                                        <button type='button' className="btn-transparent btn-product-tour">PRODUCT TOUR</button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='pagination-wrap'>
                                        <ul className="pagination">
                                            {capabilities.length > 0 && capabilities.map((item, index) => <li key={index}><button className={coreCapabilities === item.title ? 'active' : undefined} onClick={() => handleCapabilitiesActive(item.title)}></button></li>)}
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg="auto">
                                    <div className="controls">
                                        <button className='prev' onClick={handlePrev}><img src={require('./../assets/images/carousel-arrow-prev.png')} alt="Previous" width="15" /></button>
                                        <button className='next' onClick={handleNext}><img src={require('./../assets/images/carousel-arrow-next.png')} alt="Next" width="15" /></button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="counter">
                            <Row>
                                <Col md="3" className="item pb-5 pb-md-0">
                                    <span className="counter-text"><Ticker end={90} />%</span>
                                    <span className="text">Reduced data <br/>footprint</span>
                                </Col>
                                <Col md="3" className="item pb-5 pb-md-0">
                                    <span className="counter-text"><Ticker end={300} />%+</span>
                                    <span className="text">Increase in integrated <br/>data delivery</span>
                                </Col>
                                <Col md="3" className="item pb-5 pb-md-0">
                                    <span className="counter-text"><Ticker end={70} />%+</span>
                                    <span className="text">Data management <br/>cost optimization</span>
                                </Col>
                                <Col md="3" className="item pb-5 pb-md-0">
                                    <span className="counter-text"><Ticker end={30} />%</span>
                                    <span className="text">Data privacy and <br/>security cost optimization</span>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <section>
                    <Container fluid>
                        <div className="section-title">
                            <h2>MARKET OPPORTUNITY</h2>
                            <h3>Secure, Seamless, Scalable, and Sustainable</h3>
                            <p>State-of-the-art and personalized analytics, powered by AI, in the hands of mid-market business users to accelerate growth and maximize RoI in half the time and at one-third the cost</p>
                        </div>
                        <Row>
                            <Col xs={12} lg="auto">
                                <ul className='statements'>
                                    {statements.length > 0 && statements.map((item, index) => (
                                        <li key={index} className={statementActive === item.text1 ? 'active' : undefined} onMouseEnter={() => handleStatementsActive(item.text1)}>
                                            <span>{item.text1}</span>
                                            <span className='txt-yellow'><i>{item.text2}</i></span>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                            <Col xs={12} lg="auto" style={{flex:1}}>
                                {statementActive === 'Your data belongs to you' && <video width="100%" height="400" controls muted loop autoPlay>
                                    <source src={require('./../assets/videos/YourDataBelongsToYou.mp4')} type="video/mp4"/>
                                    Your browser does not support HTML video.
                                </video>}
                                {/* {statementActive === 'Your data belongs to you' && <>Your data belongs to you</>} */}
                                {statementActive === 'Reduced cost of intelligence & decisions' && <video width="100%" height="400" controls muted loop autoPlay>
                                    <source src={require('./../assets/videos/MostCostEffectiveAnalytics.mp4')} type="video/mp4"/>
                                    Your browser does not support HTML video.
                                </video>}
                                {statementActive === 'AI-driven recommendations & decisions' && <video width="100%" height="400" controls muted loop autoPlay>
                                    <source src={require('./../assets/videos/MakesDecisions.mp4')} type="video/mp4"/>
                                    Your browser does not support HTML video.
                                </video>}
                                {statementActive === 'Personalized, real-time, intelligence assistance' && <video width="100%" height="400" controls muted loop autoPlay>
                                    <source src={require('./../assets/videos/Friction-lessPersonalization.mp4')} type="video/mp4"/>
                                    Your browser does not support HTML video.
                                </video>}
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container fluid>
                        <div className="section-title">
                            <h2>INDUSTRY SOLUTIONS</h2>
                            <h3>The only analytics platform that knows your industry</h3>
                            <p>Athena knows your market, your industry, and your business functions, and is here to offer intelligence and decisions to C-suite stakeholders, functional heads, and business decision - makers</p>
                        </div>
                        <Row xs={1} md={2} lg={4} className='industry-solutions'>
                            <Col>
                                <div className='item'>
                                    <div>
                                        <span className='image'><img src={require('./../assets/images/sales.png')} alt="Retail, CPG & E-Com" /></span>
                                        <span className='title'>Retail, CPG & E-Com</span>
                                        <div className='overlay'>
                                            <span className='title'>Retail, CPG & E-Com</span>
                                            <span className='button'><button className='btn-yellow' onClick={() => navigate("/analytics", {state: "retail" })}>Learn More</button></span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className='item'>
                                    <div>
                                        <span className='image'><img src={require('./../assets/images/finance.png')} alt="Financial Services" /></span>
                                        <span className='title'>Financial Services</span>
                                        <div className='overlay'>
                                            <span className='title'>Financial Services</span>
                                            <span className='button'><button className='btn-yellow' onClick={() => navigate("/analytics", {state: "financial-services" })}>Learn More</button></span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className='item'>
                                    <div>
                                        <span className='image'><img src={require('./../assets/images/claims.png')} alt="Insurance" /></span>
                                    <span className='title'>Insurance</span>
                                        <div className='overlay'>
                                            <span className='title'>Insurance</span>
                                            <span className='button'><button className='btn-yellow' onClick={() => navigate("/analytics", {state: "insurance" })}>Learn More</button></span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className='item'>
                                    <div>
                                        <span className='image'><img src={require('./../assets/images/care.png')} alt="Healthcare" /></span>
                                    <span className='title'>Healthcare</span>
                                        <div className='overlay'>
                                            <span className='title'>Healthcare</span>
                                            <span className='button'><button className='btn-yellow' onClick={() => navigate("/analytics", {state: "healthcare" })}>Learn More</button></span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="inspiring-strategy bg" style={{ backgroundImage: `url(${require("./../assets/images/inspiring-strategy-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>INSPIRING STRATEGY</h2>
                            <h3>Disruptive Technologies Platform</h3>
                            <p>ATHENA is a Plug N Play intelligent enterprise data management and decision sciences platform that transforms businesses into data-driven, intelligent, high performing, and adaptive enterprises.</p>
                        </div>
                        <Row xs={1} md={2} lg={4}>
                            <Col className="item pb-5 pb-lg-0">
                                <span className="image"><img src={require('./../assets/images/revenues.png')} alt="Revenues" /></span>
                                <span className="text"><span className="percentage"><Ticker end={15}/>%+</span> growth in revenues <br/>with market strategies</span>
                            </Col>
                            <Col className="item  pb-5 pb-lg-0">
                                <span className="image"><img src={require('./../assets/images/optimization.png')} alt="Optimization" /></span>
                                <span className="text"><span className="percentage"><Ticker end={30}/>%+</span> optimization in <br/>cost of doing business</span>
                            </Col>
                            <Col className="item pb-5 pb-lg-0">
                                <span className="image"><img src={require('./../assets/images/efficiency.png')} alt="Efficiency" /></span>
                                <span className="text"><span className="percentage"><Ticker end={50}/>%+</span> efficiency in operations, <br/>driving up business performance</span>
                            </Col>
                            <Col className="item pb-5 pb-lg-0">
                                <span className="image"><img src={require('./../assets/images/productivity.png')} alt="Productivity" /></span>
                                <span className="text"><span className="percentage"><Ticker end={70}/>%+</span> productivity in <br/>enterprise data management</span>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container fluid>
                        <div className="section-title">
                            <h2>INNOVATION ECOSYSTEM</h2>
                            <h3>Adapt The Future</h3>
                            <p>We harness the potential of AI, Data Mesh, Knowledge Graph, Active Metadata and Autonomous Technologies to build future-ready capabilities that solve real world problems</p>
                        </div>
                        <div className="acuverse-details">
                            <Row xs={1} sm={2} lg={4}>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/metaverse-small.png')} width="70" alt="Metaverse" /></span>
                                            <span className="text">METAVERSE</span>
                                        </div>
                                        <div className="flip-card-back">
                                            <p><span className="txt-orange">METAVERSE</span> defines and contains the data foot-print with data ontology to control data integrity and optimize data use, purpose, flow, and quality delivery.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/nexverse-small.png')} width="70" alt="Nexverse" /></span>
                                            <span className="text">NEXVERSE</span>
                                        </div>
                                        <div className="flip-card-back">
                                            <p><span className="txt-orange">NEXVERSE</span> contains data, controls data nexus, autonomously discovering data and data patterns, to build a dynamic data fabric and control dynamic data delivering</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/intelverse-small.png')} width="70" alt="Intelverse" /></span>
                                            <span className="text">INTELVERSE</span>
                                        </div>
                                        <div className="flip-card-back">
                                            <p><span className="txt-orange">INTELVERSE</span> derives business intelligence to analyze and predict business performance, and execute business strategy with decision support systems</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/securverse-small.png')} width="70" alt="Securverse" /></span>
                                            <span className="text">SECURVERSE</span>
                                        </div>
                                        <div className="flip-card-back">
                                            <p><span className="txt-orange">SECURVERSE</span> embraces data privacy, defines data that needs protection, controls enterprise data flow, and governs who has what access to enterprise data</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <section className="read-our-articles">
                    <Container fluid>
                        <div className="section-title">
                            <h2>Our Perspective</h2>
                        </div>
                        <Row className="articles">
                            <Col md="6" className="item" onClick={() => window.open("https://www.linkedin.com/pulse/time-now-risingahead/", '_blank')}>
                                <h4>Time is Now</h4>
                                <Row>
                                    <Col xs={12} sm md={12} lg>
                                        <p>Businesses are failing to contain and harness the potential of data. 80% of organizations will fail by 2025 because they do not take a modern approach to data and analytics governance.</p>
                                    </Col>
                                    <Col xs={12} sm md={12} lg>
                                        <img src={require('./../assets/images/time-is-now.png')} alt="Time is Now" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6" className="item" onClick={() => window.open("https://www.linkedin.com/pulse/adapt-future-time-now-risingahead/", '_blank')}>
                                <h4>Adapt the future</h4>
                                <Row>
                                    <Col xs={12} sm md={12} lg>
                                        <p>Markets are experiencing rising gulf size gap in capabilities and business readiness. 75% of conversations at work will be recorded and analyzed by 2025 enabling the discovery of added organizational value.</p>
                                    </Col>
                                    <Col xs={12} sm md={12} lg>
                                        <img src={require('./../assets/images/adapt-the-future.png')} alt="Adapt the future" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6" className="item" onClick={() => window.open("https://www.linkedin.com/pulse/live-your-purpose-time-now-risingahead/", '_blank')}>
                                <h4>Live your purpose</h4>
                                <Row>
                                    <Col xs={12} sm md={12} lg>
                                        <p>Enterprises are failing because data has drowned their purpose. 75% of organizations will have established a data and analytics center of excellence by 2024 to prevent enterprise failure.</p>
                                    </Col>
                                    <Col xs={12} sm md={12} lg>
                                        <img src={require('./../assets/images/live-your-purpose.png')} alt="Live your purpose" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6" className="item" onClick={() => window.open("https://www.linkedin.com/pulse/inspire-strategy-time-now-risingahead/", '_blank')}>
                                <h4>Inspire strategy</h4>
                                <Row>
                                    <Col xs={12} sm md={12} lg>
                                        <p>30% of organizations will harness the collective intelligence of their analytics communities, by 2023, outperforming competitors that rely solely on centralized analytics or self-service. Organization strategy is informed by intelligence.</p>
                                    </Col>
                                    <Col xs={12} sm md={12} lg>
                                        <img src={require('./../assets/images/inspire-strategy.png')} alt="Inspire strategy" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
            <StickyIcons isEntrera={true} isAcuverse={true} isDoorway={true} />
        </>
    );
}
 
export default Home;