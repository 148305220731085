import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/header';
import Footer from '../components/footer';
import StickyIcons from '../components/stickyIcons';
import PagetTitle from "../components/PageTitle";

function Marketplace() {
    const handleDemo = (event)=> {
        let requestdemo = document.getElementById('Athena_Demo_Form');
        localStorage.setItem('buttoncontext',event.target.dataset.context);
        requestdemo.click();
    }
    return (
        <>
            <Header isentrera={true} isacuverse={true} isdoorway={true} isMarketplace={true}/>
            <PagetTitle title="Athena | Marketplace"/>
            <main>
                <section className="marketplace bg" style={{ backgroundImage: `url(${require("./../assets/images/marketplace-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h1>MARKETPLACE</h1>
                            <h3>New Revenue Streams</h3>
                            <p>Athena Marketplace creates new markets, drives interoperable strategies, and delivers new revenue growth opportunities.</p>
                        </div>
                        <Row className="icon-details-list">
                            <Col>
                                <div className="image"><img src={require('./../assets/images/data-services.png')} width="60" alt="Data Services" /></div>
                                <h3>Data Services</h3>
                                <ul>
                                    <li>
                                        <span className="text">Market share penetration strategies</span>
                                    </li>
                                    <li>
                                        <span className="text">New Product Development strategies</span>
                                    </li>
                                    <li>
                                        <span className="text">Consumer Engagement strategies</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <div className="image"><img src={require('./../assets/images/ecosystems.png')} width="70" alt="Ecosystems" /></div>
                                <h3>Ecosystems</h3>
                                <ul>
                                    <li>
                                        <span className="text">Strategic member Participation</span>
                                    </li>
                                    <li>
                                        <span className="text">Autonomous Data Price discovery</span>
                                    </li>
                                    <li>
                                        <span className="text">Seamless &amp; Frictionless Data trade</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <div className="image"><img src={require('./../assets/images/new-markets.png')} width="60" alt="New Markets" /></div>
                                <h3>New Markets</h3>
                                <ul>
                                    <li>
                                        <span className="text">Strategic Data &amp; Trend Discoveries</span>
                                    </li>
                                    <li>
                                        <span className="text">New Revenue Model Design</span>
                                    </li>
                                    <li>
                                        <span className="text">Generate new revenue streams</span>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="buy-insights">
                    <Container fluid>
                        <div className="section-title">
                            <h2>INSIGHT OFFERINGS</h2>
                            <h3>What We Serve</h3>
                        </div>
                        <div className="wrapper">
                            <Row xs={1} md={2} lg={4}>
                                <Col>
                                    <h4>US Mortgage</h4>
                                    <div className="image"><img src={require('./../assets/images/us1.png')} width="300" alt="US" className="img-responsive" /></div>
                                    <ul>
                                        <li>
                                            <img src={require('./../assets/images/Originations-Trends.png')} alt="Origination Trends" className="img-responsive" />
                                            <span className="text">Origination Trends</span>
                                            <span className="overlay">
                                                <h5>Origination Trends</h5>
                                                <p>Mortgage origination, rates, and regional risk price trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Origination Trends">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/servicer-trends.png')} alt="Servicer Trends" className="img-responsive" />
                                            <span className="text">Servicer Trends</span>
                                            <span className="overlay">
                                                <h5>Servicer Trends</h5>
                                                <p>Mortgage servicing cost, process efficiency, and service compliance trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Servicer Trends">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/Repayment-Performance-Trends.png')} alt="Repayment Performance Trends" className="img-responsive" />
                                            <span className="text">Repayment Performance Trends</span>
                                            <span className="overlay">
                                                <h5>Repayment Performance Trends</h5>
                                                <p>Regional mortgage repayment delays and delinquency trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Repayment Performance Trends">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/Housing-Market-Trends.png')} alt="Housing Market Trends" className="img-responsive" />
                                            <span className="text">Housing Market Trends</span>
                                            <span className="overlay">
                                                <h5>Housing Market Trends</h5>
                                                <p>Regional borrower repayment behavior and lender collateral risk trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Housing Market Trends">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <h4>US Healthcare</h4>
                                    <div className="image"><img src={require('./../assets/images/us2.png')} width="300" alt="US" className="img-responsive" /></div>
                                    <ul>
                                        <li>
                                            <img src={require('./../assets/images/care-access.png')} alt="Care Access" className="img-responsive" />
                                            <span className="text">Care Access</span>
                                            <span className="overlay">
                                                <h5>Care Access</h5>
                                                <p>Regional care services and population segment access trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Care Access">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/care-issues.png')} alt="Care Issues" className="img-responsive" />
                                            <span className="text">Care Issues</span>
                                            <span className="overlay">
                                                <h5>Care Issues</h5>
                                                <p>Regional care issues and population segment care trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Care Issues">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/behavior-changes.png')} alt="Behavior Changes" className="img-responsive" />
                                            <span className="text">Behavior Changes</span>
                                            <span className="overlay">
                                                <h5>Behavior Changes</h5>
                                                <p>Regional change in health behaviors and population segment care trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Behavior Changes">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/care-coverage.png')} alt="Care Coverage" className="img-responsive" />
                                            <span className="text">Care Coverage</span>
                                            <span className="overlay">
                                                <h5>Care Coverage</h5>
                                                <p>Regional care coverage and population segment coverage trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Care Coverage">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <h4>US Aviation</h4>
                                    <div className="image"><img src={require('./../assets/images/us3.png')} width="300" alt="US" className="img-responsive" /></div>
                                    <ul>
                                        <li>
                                            <img src={require('./../assets/images/operations-efficiency.png')} alt="Operations Efficiency" className="img-responsive" />
                                            <span className="text">Operations Efficiency</span>
                                            <span className="overlay">
                                                <h5>Operations Efficiency</h5>
                                                <p>Airline, airport, and airplane operations efficiency trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Operations Efficiency">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/find-me-my-flight.png')} alt="Find me my flight" className="img-responsive" />
                                            <span className="text">Find me my flight</span>
                                            <span className="overlay">
                                                <h5>Find me my flight</h5>
                                                <p>Traveler interest and appetite-based airline and airport recommendations.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Find me my flight">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/fuel-efficient-airlines.png')} alt="Fuel Efficient Airlines" className="img-responsive" />
                                            <span className="text">Fuel Efficient Airlines</span>
                                            <span className="overlay">
                                                <h5>Fuel Efficient Airlines</h5>
                                                <p>Route-based airline, airport, and airplane fuel consumption and efficiency trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Fuel Efficient Airlines">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/esg.png')} alt="Carbon Emissions [ESG]" className="img-responsive" />
                                            <span className="text">Carbon Emissions [ESG]</span>
                                            <span className="overlay">
                                                <h5>Carbon Emissions [ESG]</h5>
                                                <p>Route-based airline, airport, and airplane carbon emission trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Carbon Emissions [ESG]">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <h4>India Market Watch</h4>
                                    <div className="image"><img src={require('./../assets/images/india-market.png')} alt="India Market Watch" className="img-responsive" /></div>
                                    <ul>
                                        <li>
                                            <img src={require('./../assets/images/borrower-behavior.png')} alt="Borrower Behavior" className="img-responsive" />
                                            <span className="text">Borrower Behavior</span>
                                            <span className="overlay">
                                                <h5>Borrower Behavior</h5>
                                                <p>Vulnerable population segments and credit exposure trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Carbon Emissions [ESG]">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/lender-behavior.png')} alt="Lender Behavior" className="img-responsive" />
                                            <span className="text">Lender Behavior</span>
                                            <span className="overlay">
                                                <h5>Lender Behavior</h5>
                                                <p>Product-based regional commercial bank lending and credit exposures trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Lender Behavior">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/credit-exposures.png')} alt="Credit Exposure" className="img-responsive" />
                                            <span className="text">Credit Exposure</span>
                                            <span className="overlay">
                                                <h5>Credit Exposure</h5>
                                                <p>Product and population segment-based regional credit exposure trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Credit Exposure">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/collateral-risks.png')} alt="Collateral Risks" className="img-responsive" />
                                            <span className="text">Collateral Risks</span>
                                            <span className="overlay">
                                                <h5>Collateral Risks</h5>
                                                <p>Product-based regional commercial bank collateral risk trends.</p>
                                                <button className="btn-yellow" onClick={handleDemo} data-context="Credit ExposuresCollateral Risks">REQUEST DEMO</button>
                                            </span>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <section className="stakeholders">
                    <Container fluid>
                        <div className="section-title">
							<h2>INSPIRING STRATEGY</h2>
							<h3>Know Your Market</h3>
                            <p>Athena knows your market, your industry, and your customers and it is here to inform your strategyto penetrate into new customersegments and grow yourmarket share.</p>
                        </div>
                        <Row xs={1} md={2} lg={4}>
                            <Col className="item">
                                <div className="image">
                                    <img src={require('./../assets/images/mp-strategy-owners.png')} className="img-responsive" alt="Strategy Owners" />
                                    <div className="details">
                                        <div className="image"><img src={require('./../assets/images/strategy-owners-icon.png')} width="42" alt="Strategy Owners" /></div>
                                        <h3>Strategy Owners</h3>
                                        <ul>
                                            <li>Chief Executive Officer</li>
                                            <li>Chief Finance Officer</li>
                                            <li>Chief Strategy Officer</li>
                                        </ul>
                                    </div>
                                    <div className="overlay">
                                        <h3>Strategy Owners</h3>
                                        <ul>
                                            <li>Competitor Insights</li>
                                            <li>Consumer Insights</li>
                                            <li>Product Insights</li>
                                            <li>Market Penetration Insights</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="image">
                                    <img src={require('./../assets/images/mp-growth-owners.png')} className="img-responsive" alt="Growth Owners" />
                                    <div className="details">
                                        <div className="image"><img src={require('./../assets/images/growth-owners-icon.png')} width="50" alt="Growth Owners" /></div>
                                        <h3>Growth Owners</h3>
                                        <ul>
                                            <li>Chief Marketing Officer</li>
                                            <li>Chief Sales Officer</li>
                                            <li>Chief Growth Officer</li>
                                        </ul>
                                    </div>
                                    <div className="overlay">
                                        <h3>Growth Owners</h3>
                                        <ul>
                                            <li>Consumer Insights</li>
                                            <li>Product Insights</li>
                                            <li>Regional Insights</li>
                                            <li>Pricing Insights</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="image">
                                    <img src={require('./../assets/images/mp-operations-owners.png')} className="img-responsive" alt="Operations Owners" />
                                    <div className="details">
                                        <div className="image"><img src={require('./../assets/images/operations-owners-icon.png')} width="50" alt="Operations Owners" /></div>
                                        <h3>Operations Owners</h3>
                                        <ul>
                                            <li>Chief Operating Officer</li>
                                            <li>Chief Digital Officer</li>
                                            <li>Chief Technology Officer</li>
                                        </ul>
                                    </div>
                                    <div className="overlay">
                                        <h3>Operations Owners</h3>
                                        <ul>
                                            <li>Risk Insights</li>
                                            <li>Customer Service Insights</li>
                                            <li>Customer Satisfaction Insights</li>
                                            <li>Brand Reputation Insights</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="image">
                                    <img src={require('./../assets/images/mp-information-owners.png')} className="img-responsive" alt="Information Owners" />
                                    <div className="details">
                                        <div className="image"><img src={require('./../assets/images/information-owners-icon.png')} width="50" alt="Information Owners" /></div>
                                        <h3>Information Owners</h3>
                                        <ul>
                                            <li>Chief Information Officer</li>
                                            <li>Chief Data Officer</li>
                                            <li>Chief Security Officer</li>
                                        </ul>
                                    </div>
                                    <div className="overlay">
                                        <h3>Information Owners</h3>
                                        <ul>
                                            <li>External Data Sources</li>
                                            <li>Data Services</li>
                                            <li>New Market Services</li>
                                            <li>Ecosystem Services</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="marketplace-ds">
                    <Container fluid>
                        <div className="section-title">
                            <h2>INSIGHT OFFERINGS</h2>
                            <h3>Data Monetization Strategies</h3>
                            <p>Data and insight modules for decision makers available on pay per month subscription</p>
                        </div>
                        <Row xs={1} sm={3}>
                            <Col className="item">
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/data-services-gray.png')} alt="Data Services" width="90" className="img-responsive" /></span>
                                    <span className="text">Data Services</span>
                                    <span className="overlay">Market, industry, competition, and consumer behavior trends help you plan and execute your business strategy.</span>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/ecosystem-gray.png')} alt="Ecosystem" width="120" className="img-responsive" /></span>
                                    <span className="text">Ecosystem</span>
                                    <span className="overlay">Marketplace where subscribers and providers buy and sell data and insights, securely, conducting a frictionless data trade.</span>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/new-markets-gray.png')} alt="New Markets" width="100" className="img-responsive" /></span>
                                    <span className="text">New Markets</span>
                                    <span className="overlay">New revenue opportunities from your internal enterprise data by selling data and insights into the marketplace as a provider.</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
            <StickyIcons isEntrera={true} isAcuverse={true} isDoorway={true} />
        </>
    );
}
 
export default Marketplace;