import React,{useState} from 'react';
import { Container, Row, Col} from 'react-bootstrap';

import './../assets/css/styles.css';
import Header from '../components/header';
import Footer from '../components/footer';
import StickyIcons from '../components/stickyIcons';
import PagetTitle from "../components/PageTitle";
import Ticker from "../components/Ticker";

function DataMonetization() {

    const [video1Show, setVideo1Show] = useState("");
    const [video2Show, setVideo2Show] = useState("");
    const [video3Show, setVideo3Show] = useState("");
    const [video4Show, setVideo4Show] = useState("");
    const [videoShow2, setVideoShow2] = useState("");

    const handleVideo1Show = () => {
        setVideo1Show("show");
        setVideo2Show("");
        setVideo3Show("");
        setVideo4Show("");
    }
    const handleVideo2Show = () => {
        setVideo2Show("show");
        setVideo1Show("");
        setVideo3Show("");
        setVideo4Show("");
    }
    const handleVideo3Show = () => {
        setVideo3Show("show");
        setVideo1Show("");
        setVideo2Show("");
        setVideo4Show("");
    }
    const handleVideo4Show = () => {
        setVideo4Show("show");
        setVideo1Show("");
        setVideo2Show("");
        setVideo3Show("");
    }

    return (
        <>
            <Header isentrera={true} isacuverse={true} isdoorway={true}/>
            <PagetTitle title="Athena | Data Monetization"/>
            <main>
                <section className="innovation-ecosystem bg" style={{ backgroundImage: `url(${require("./../assets/images/innovation-ecosystem-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>INNOVATION ECOSYSTEM</h2>
                            <h3>Data Monetization Platform</h3>
                            <p>We unlock your data potential to inform your business strategy and monetize data, to maximize return on investment and create new revenue channels</p>
                        </div>
                        <Row xs={1} md={1} lg={3}>
                            <Col>
                                <h4><img src={require('./../assets/images/saas.png')} width="75" alt="Analytics Platform" />Analytics Platform</h4>
                                <ul>
                                    <li>Secures Data and Controls Quality</li>
                                    <li>Integrates &amp; Delivers Data at 3x Speed</li>
                                    <li>Accelerates Strategic Decision Making</li>
                                    <li>Creates Revenue Growth Strategies</li>
                                </ul>
                            </Col>
                            <Col className='logo-large'>
                                
                            </Col>
                            <Col>
                                <h4><img src={require('./../assets/images/marketplace.png')} width="45" alt="Marketplace" />Marketplace</h4>
                                <ul>
                                    <li>Informs Market and Consumer Behaviors</li>
                                    <li>Creates Data Monetization Opportunities</li>
                                    <li>Brings Market Participants together</li>
                                    <li>Delivers Frictionless Data Trade</li>
                                </ul>
                            </Col>
                        </Row>
                        <div className="text-bottom"><p>ATHENA places data and analytics at the heart of your business</p></div>
                    </Container>
                </section>
                <section className="inspiring-strategy bg" style={{ backgroundImage: `url(${require("./../assets/images/inspiring-strategy-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>INSPIRING STRATEGY</h2>
                            <h3>Disruptive Technologies Platform</h3>
                            <p>ATHENA is a Plug N Play intelligent enterprise data management and decision sciences platform that transforms businesses into data-driven, intelligent, high performing, and adaptive enterprises.</p>
                        </div>
                        <Row xs={1} md={2} lg={4}>
                            <Col className="item pb-5 pb-lg-0">
                                <span className="image"><img src={require('./../assets/images/revenues.png')} alt="Revenues" /></span>
                                <span className="text"><span className="percentage"><Ticker end={15}/>%+</span> growth in revenues <br/>with market strategies</span>
                            </Col>
                            <Col className="item  pb-5 pb-lg-0">
                                <span className="image"><img src={require('./../assets/images/optimization.png')} alt="Optimization" /></span>
                                <span className="text"><span className="percentage"><Ticker end={30}/>%+</span> optimization in <br/>cost of doing business</span>
                            </Col>
                            <Col className="item pb-5 pb-lg-0">
                                <span className="image"><img src={require('./../assets/images/efficiency.png')} alt="Efficiency" /></span>
                                <span className="text"><span className="percentage"><Ticker end={50}/>%+</span> efficiency in operations, <br/>driving up business performance</span>
                            </Col>
                            <Col className="item pb-5 pb-lg-0">
                                <span className="image"><img src={require('./../assets/images/productivity.png')} alt="Productivity" /></span>
                                <span className="text"><span className="percentage"><Ticker end={70}/>%+</span> productivity in <br/>enterprise data management</span>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="adapt-future bg" style={{ backgroundImage: `url(${require("./../assets/images/adapt-future-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>ADAPT THE FUTURE</h2>
                            <h3>Resources are scarce, enterprises must ride this wave of technology adoption</h3>
                        </div>
                        <Row className="video">
                            <Col xs={12} lg={4}>
                                <div>
                                    <button type="button" id="Data_Monetization_Platform" className="video-text" onClick={() => handleVideo1Show()}>
                                        <span className="image"><img id="icon_Data_Monetization_Platform" src={require('./../assets/images/video.png')} alt="Video" /></span>
                                        <span className="text" id="text_Data_Monetization_Platform">Data Monetization Platform</span>
                                    </button>
                                </div>
                                <div className="mt-4">
                                    <button type="button" id="80%_of_businesses_will_fail_by_2025" className="video-text" onClick={() => handleVideo2Show()}>
                                        <span className="image"><img id="icon_80%_of_businesses_will_fail_by_2025" src={require('./../assets/images/video.png')} alt="Video" /></span>
                                        <span className="text" id="text_80%_of_businesses_will_fail_by_2025">80% of businesses will fail by 2025</span>
                                    </button>
                                </div>
                                <div className="mt-4">
                                    <button type="button" id="Time_Is_Now" className="video-text" onClick={() => handleVideo3Show()}>
                                        <span className="image"><img id="icon_Time_Is_Now" src={require('./../assets/images/video.png')} alt="Video" /></span>
                                        <span className="text" id="text_Time_Is_Now">Time Is Now</span>
                                    </button>
                                </div>
                                <div className="mt-4">
                                    <button type="button" id="Adapt_The_Future" className="video-text" onClick={() => handleVideo4Show()}>
                                        <span className="image"><img id="icon_Adapt_The_Future" src={require('./../assets/images/video.png')} alt="Video" /></span>
                                        <span className="text" id="text_Adapt_The_Future">Adapt The Future</span>
                                    </button>
                                </div>
                            </Col>
                            <Col xs={12} lg={8} xl={5}>
                                <div className="video-iframe">
                                    <div className={video1Show}><iframe width="560" height="380" src="https://www.youtube.com/embed/MRfd08sI_Wc?rel=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                    <div className={video2Show}><iframe width="560" height="380" src="https://www.youtube.com/embed/tPLdznzRNyU?rel=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                    <div className={video3Show}><iframe width="560" height="380" src="https://www.youtube.com/embed/TXh9GR6pj4A?rel=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                    <div className={video4Show}><iframe width="560" height="380" src="https://www.youtube.com/embed/a7vvfzTi0ic?rel=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="insanity bg" style={{ backgroundImage: `url(${require("./../assets/images/insanity-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h3>Doing the same thing over and over again and expecting different results is insanity.</h3>
                        </div>
                        <Row className="align-items-center">
                            <Col xs={12} sm={12} md={4} className="video">
                                <div className={"video-iframe " + videoShow2}>
                                    <iframe width="560" height="380" src="https://www.youtube.com/embed/_5FXgfFjr6k?rel=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                                <button type="button" id="How_Athena_Is_Different" className="video-text" onClick={() => setVideoShow2("show")}>
                                    <span className="image"><img id="icon_How_Athena_Is_Different" src={require('./../assets/images/video.png')} alt="Video" /></span>
                                    <span className="text" id="text_How_Athena_Is_Different">How Athena<sup className="text_How_Athena_Is_Different">TM</sup> Is Different</span>
                                </button>
                            </Col>
                            <Col xs={12} sm={6} md={4} className="business-challenges">
                                <h4><img src={require('./../assets/images/business-challenges.png')} alt="Business Challenges" />Business Challenges</h4>
                                <ul>
                                    <li>Poor data quality</li>
                                    <li>Rising privacy threats</li>
                                    <li>Rising data management costs</li>
                                    <li>Poor speed of information</li>
                                    <li>Missed growth opportunities</li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <h4><img src={require('./../assets/images/athena-A.png')} alt="Differentiation" />Differentiation</h4>
                                <ul className="txt-orange">
                                    <li>Better over more data</li>
                                    <li>Speed and growth enabling security</li>
                                    <li>Efficient, replicable, scalable process</li>
                                    <li>Decisive, unbiased, real-time information</li>
                                    <li>New markets and new revenue streams</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
            <StickyIcons isEntrera={true} isAcuverse={true} isDoorway={true} />
        </>
    );
}
 
export default DataMonetization;