import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import Header from '../components/header';
import Footer from '../components/footer';
import StickyIcons from '../components/stickyIcons';
import PagetTitle from "../components/PageTitle";

function AboutUs() {
    return (
        <>
            <Header isentrera={true} isacuverse={true} isdoorway={true}/>
            <PagetTitle title="Athena | About Us"/>
            <main>
                <section className="bg bg-no-repeat bg-center-bottom bg-cover" style={{ backgroundImage: `url(${require("./../assets/images/about-us-bg.jpg")})` }}>
                    <Container fluid>
                        <div className="section-title">
                          <h1>OUR TEAM</h1>
                          <h3>Driving Innovation</h3>
                          <p>Our leaders are united in purpose and poised to make a dent in the universe</p>
                        </div>
                        <div className='our-team'>
                          <Row xs={1} md={2} lg={3}>
                            <Col className="item">
                              <div className="image">
                                <div className='text-center'>
                                  <img src={require('./../assets/images/rahul-arora.png')} className="img-responsive" alt="Rahul Arora" />
                                </div>
                                <div className="details">
                                  <h3>Rahul Arora</h3>
                                  <ul>
                                    <li>Founder &amp; Chief Executive Office</li>
                                  </ul>
                                </div>
                                <div className="overlay">
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <h3>Rahul Arora</h3>
                                    <div>
                                      <a href="https://www.linkedin.com/in/rahularoraorg/" target="_blank"><img src={require('./../assets/images/linkedin-icon.png')} height="15" alt="LinkedIn" /></a>
                                      <a href="mailto:rahul.arora@risingahead.com"><img src={require('./../assets/images/email-icon.png')} height="15" alt="Email" /></a>
                                    </div>
                                  </div>
                                  <p>Building and driving business strategy to deliver disruptive business capabilities and to simplify client business with emerging technologies-led innovation ecosystem.</p>
                                </div>
                              </div>
                            </Col>
                            <Col className="item">
                              <div className="image">
                                <div className='text-center'>
                                  <img src={require('./../assets/images/gerard-antony.png')} className="img-responsive" alt="Gerard Antony" />
                                </div>
                                <div className="details">
                                  <h3>Gerard Antony</h3>
                                  <ul>
                                    <li>Co-founder &amp; Chief Platform Engineer</li>
                                  </ul>
                                </div>
                                <div className="overlay">
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <h3>Gerard Antony</h3>
                                    <div>
                                      <a href="https://www.linkedin.com/in/gerardantony" target="_blank"><img src={require('./../assets/images/linkedin-icon.png')} height="15" alt="LinkedIn" /></a>
                                      <a href="mailto:gerard.antony@risingahead.com"><img src={require('./../assets/images/email-icon.png')} height="15" alt="Email" /></a>
                                    </div>
                                  </div>
                                  <p>Building and driving "next-gen" data transformation capabilities to architect, design and engineer disruptive platform for delivering cross-industry business strategy.</p>
                                </div>
                              </div>
                            </Col>
                            <Col className="item">
                              <div className="image">
                                <div className='text-center'>
                                  <img src={require('./../assets/images/joanna-pasquale.png')} className="img-responsive" alt="Joanna Pasquale" />
                                </div>
                                <div className="details">
                                  <h3>Joanna Pasquale</h3>
                                  <ul>
                                    <li>Chief Growth Office Advisor &amp; Retail Head</li>
                                  </ul>
                                </div>
                                <div className="overlay">
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <h3>Joanna Pasquale</h3>
                                    <div>
                                      <a href="https://www.linkedin.com/in/joanna-pasquale-6b684a1b7" target="_blank"><img src={require('./../assets/images/linkedin-icon.png')} height="15" alt="LinkedIn" /></a>
                                      <a href="mailto:joanna.pasquale@risingahead.com"><img src={require('./../assets/images/email-icon.png')} height="15" alt="Email" /></a>
                                    </div>
                                  </div>
                                  <p>Driving business development in the USA, specializing in Strategy &amp; New Product Development, Strategic Consumer & Customer Analytics, and Transaction &amp; Behavior Research.</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                    </Container>
                </section>
                <section className="purpose">
                    <Container fluid>
                        <div className="section-title">
                            <h2>PURPOSE</h2>
                            <h3>Simplifying Business</h3>
                            <p>Fueling growth, in a connected world, with one step into the future</p>
                        </div>
                        <Row xs={1} sm={2} lg={3}>
                            <Col className="item">
                                <div className="inner">
                                    <img src={require('./../assets/images/Adapt The Future.png')} alt="Adapt The Future" className="img-responsive" />
                                    <div className="overlay">
                                      <h3>ADAPT THE <span className="txt-orange">FUTURE</span></h3>
                                      <p>Actively investing in architecture, design, engineering and subject matter expertise of markets, industries, and emerging business models, we solve real world problems and build disruptive business capabilities.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <img src={require('./../assets/images/Live Your Purpose.png')} alt="Live Your Purpose" className="img-responsive" />
                                    <div className="overlay">
                                      <h3>LIVE <span className="txt-orange">YOUR</span> PURPOSE</h3>
                                      <p>We fast track business transformation, and help businesses connect and engage with their customers, employees, stakeholders, and investors.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <img src={require('./../assets/images/Inspire Strategy.png')} alt="Inspire Strategy" className="img-responsive" />
                                    <div className="overlay">
                                      <h3><span className="txt-orange">INSPIRE</span> STRATEGY</h3>
                                      <p>We drive business performance, bring the pulse of the right customers and their needs, to penetrate across markets, optimize costs and maximize revenues to build sustainable, profitable, organizations.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="bg bg-no-repeat bg-center-bottom bg-cover careers" style={{ backgroundImage: `url(${require("./../assets/images/careers-bg.jpg")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>CAREERS</h2>
                            <h3>Growth Mindsets</h3>
                            <p>Entrepreneurial, passionate, inspirational professionals, who care to learn, to make a difference</p>
                        </div>
                        <div className="bottom-text">
                            <p>Purpose defines a business vision and passion fuels it with the energy to deliver the mission. Leaders driven by a higher purpose build disruptive businesses. Leaders who find meaning and satisfaction in threats and exposures to undertake exhaustive efforts and compelling acts towards building an inspirational enterprise.</p>
                            <p><strong className='txt-blink'>Hiring Soon</strong></p>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer />
            <StickyIcons isEntrera={true} isAcuverse={true} isDoorway={true} isAboutUs={true} />
        </>
    );
}
 
export default AboutUs;