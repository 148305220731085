import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import Analytics from './pages/Analytics';
import Marketplace from './pages/Marketplace';
import DataMonetization from './pages/DataMonetization';
import Care from './pages/Care';
import Acuverse from './pages/Acuverse';
import Doorway from './pages/Doorway';
import Entrera from './pages/Entrera';
import AboutUs from './pages/AboutUs';
import EnterpriseLogin from './pages/EnterpriseLogin';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ScrollToTop from './components/scrollToTop';
import Cookies from './components/cookies';
import { CookiesProvider } from "react-cookie";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/data-monetization" element={<DataMonetization />} />
        <Route path="/care" element={<Care />} />
        <Route path="/entrera" element={<Entrera />} />
        <Route path="/acuverse" element={<Acuverse />} />
        <Route path="/doorway-to-connected-world" element={<Doorway />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/enterprise-login" element={<EnterpriseLogin />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
      <CookiesProvider><Cookies /></CookiesProvider>
    </BrowserRouter>
  );
}

export default App;
