import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import AthenaWebsitePrivacyPolicy from './AthenaWebsitePrivacyPolicy';
import AthenaWebsiteTermsOfUse from './AthenaWebsiteTermsOfUse';

function Footer() {
    const [showTermsOfUse, setShowTermsOfUse] = useState(false);
    const handleCloseTermsOfUse = () => setShowTermsOfUse(false);
    const handleShowTermsOfUse = () => setShowTermsOfUse(true);

    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const handleClosePrivacyPolicy = () => setShowPrivacyPolicy(false);
    const handleShowPrivacyPolicy = () => setShowPrivacyPolicy(true);
    
    return (
        <footer>
            <Container fluid>
                <div className="footer-top">
                    <Row>
                        <Col md={4}>
                            <h4><a href="https://www.risingahead.com/" target="_blank" rel="noreferrer"><img src={require('./../assets/images/Rising-Ahead-Name-Logo.png')} width="300" alt="Rising Ahead Logo" className="img-responsive"/></a></h4>
                            <p>
                                <a href="mailto:athena@risingahead.com">athena@risingahead.com</a>
                                <br/>
                                <a href="tel:+1.415.799.8993">+1.415.799.8993</a>
                            </p>
                        </Col>
                        <Col md={4}>
                            <h5>LINKS</h5>
                            <ul>
                                <li><Link to="/analytics">Analytics</Link></li>
                                <li><Link to="/marketplace">Marketplace</Link></li>
                                <li><Link to="/care">Care</Link></li>
                                <li><Link to="/entrera">Entrera</Link></li>
                            </ul>
                        </Col>
                        <Col md={4}>
                            <h5>SOCIAL MEDIA</h5>
                            <p>Follow us on social media to <br/>get latest updates</p>
                            <ul className="social-links">
                                <li><a href="https://www.linkedin.com/company/risingahead" target="_blank" rel="noreferrer"><img src={require('./../assets/images/linkedin.png')} alt="LinkedIn"/></a></li>
                                <li><a href="https://www.facebook.com/risingahead" target="_blank" rel="noreferrer"><img src={require('./../assets/images/facebook.png')} alt="Facebook"/></a></li>
                            </ul>
                        </Col>
                        <Col></Col>
                    </Row>
                </div>
                <div className="footer-bottom">
                <Row>
                <Col lg={3} className="left">Copyright &copy; Rising Ahead Inc. {new Date().getFullYear()}, USA</Col>
                <Col lg={6} className="text-center">All logos and copyrights are trademarks and the sole property of Rising Ahead Inc.</Col>
                <Col lg={3} className="right">
                    <ul>
                        <li><Link to="#" onClick={handleShowPrivacyPolicy}>Privacy Policy</Link></li>
                        <li><Link to="#" onClick={handleShowTermsOfUse}>Terms of Use</Link></li>
                    </ul>
                    </Col>
                    </Row>
                    <Modal centered show={showTermsOfUse} onHide={handleCloseTermsOfUse} backdrop="static" keyboard={false} className="terms-policies">
                        <Modal.Header closeButton>
                            <Modal.Title>Terms and Conditions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body><AthenaWebsiteTermsOfUse /></Modal.Body>
                        <Modal.Footer>
                            <button onClick={handleCloseTermsOfUse}>Back</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal centered show={showPrivacyPolicy} onHide={handleClosePrivacyPolicy} backdrop="static" keyboard={false} className="terms-policies">
                        <Modal.Header closeButton>
                            <Modal.Title>Privacy Policy</Modal.Title>
                        </Modal.Header>
                        <Modal.Body><AthenaWebsitePrivacyPolicy /></Modal.Body>
                        <Modal.Footer>
                            <button onClick={handleClosePrivacyPolicy}>Back</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </Container>
        </footer>
    )
}

export default Footer;