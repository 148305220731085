import React,{useState,useEffect} from 'react';
import {Figure} from "react-bootstrap";
import '../../src/assets/css/introanimation.css';

function IntroAnimation(props){
    const [visible,setVisibility] = useState("hidden")
    document.body.style.overflowY = visible;
    useEffect(()=>{
        setTimeout(function() {
            setVisibility("scroll");
            document.body.style.overflowY = visible;
             }, 4000);
           },
       [])
    return(
       <>
        {visible == "hidden" ?
    <div className="intro">
    <Figure>
        <Figure.Image src={props.imgsrc}/>
        <Figure.Caption><img src={props.splashlogo}/></Figure.Caption>
    </Figure>
    </div>
:<></>}
    </>
    )
    }

export default IntroAnimation;