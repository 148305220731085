import React , {useState, useEffect} from 'react';
import { Row, Col,Modal,Button, Accordion} from 'react-bootstrap';
import { useCookies } from "react-cookie";

function Cookies() {
  const [showCookieModal, setShowCookieModal] = useState(false);
  const handleCloseCookieModal = () => setShowCookieModal(false);
  const handleShowCookieModal = () => setShowCookieModal(true);

  const [cookies, setCookie] = useCookies(["user"]);
  function handleCookie() {
    setCookie("user", "athenauser", {
      path: "/"
    });
    setCloseCookies(true);
  }

  const [closeCookies, setCloseCookies] = useState(false);

  useEffect(() => {
    console.log(cookies.user);
    if(cookies.user != undefined) {
      setCloseCookies(true);
    }
  }, []);

  return (
    <div className={"cookies " + (closeCookies ? 'hide-cookies': null)}>
      <div className="cookies-wrapper">
        <Row>
          <Col xs={12} lg={7}>
            <p>By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.</p>
          </Col>
          <Col xs={12} lg={4} className="text-center">
            {/* <Button className="btn-cookie-settings" onClick={handleShowCookieModal}>Cookies Settings</Button> */}
            <Button className="btn-accept-cookies" onClick={handleCookie}>Accept Cookies</Button>
            {/* <Modal show={showCookieModal} onHide={handleCloseCookieModal} className="cookies-modal" centered>
              <Modal.Header closeButton>
                <Modal.Title><img src={require('./../assets/images/athena-A.png')} height="40" alt="Athena Logo" /></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4>Title</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non ipsum a velit finibus faucibus eu vel augue. Curabitur a egestas ligula, et faucibus diam. Sed rhoncus porta sapien, a laoreet risus fermentum id. Nulla finibus nisi sem, ut luctus ante mollis in. Nunc pharetra sagittis efficitur. Pellentesque nec neque a neque congue placerat. Vivamus in neque ut metus blandit mattis. Aliquam laoreet magna et lectus congue, at malesuada leo sodales. Suspendisse sed porttitor dolor. Sed placerat porttitor tempor. Maecenas consectetur velit nulla, sed mollis nisl pellentesque sed. Sed maximus viverra justo in pretium. Nulla suscipit diam vitae justo eleifend, vel mollis arcu maximus.</p>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header><span>Cookie Item #1 </span><span className="txt-orange">Always Active</span></Accordion.Header>
                    <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span>Cookie Item #2</span>
                      <label className="switch">
								        <input type="checkbox" checked />
								        <span className="slider"></span>
							        </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span>Cookie Item #3</span>
                      <label className="switch">
								        <input type="checkbox" checked />
								        <span className="slider"></span>
							        </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span>Cookie Item #4</span>
                      <label className="switch">
								        <input type="checkbox" checked />
								        <span className="slider"></span>
							        </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleCloseCookieModal}>Confirm</Button>
              </Modal.Footer>
            </Modal> */}
          </Col>
          <Col xs="auto" className="ms-auto">
            <Button className="btn-close" onClick={() => setCloseCookies(true)}></Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Cookies;