import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/header';
import Footer from '../components/footer';
import StickyIcons from '../components/stickyIcons';
import PagetTitle from "../components/PageTitle";

function Acuverse() {
    return (
        <>
            <Header isentrera={true} isdoorway={true} />
            <PagetTitle title="Athena | Acuverse"/>
            <main>
                <section className="acuverse bg" style={{ backgroundImage: `url(${require("./../assets/images/acuverse-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h1>ACUVERSE</h1>
                            <h3>Business Acumen Engine</h3>
                            <p>Acuverse is Athena's acumen engine that builds the enterprise data backbone and learns from business, industry, and market performance to inspire Business strategy and monetise data</p>
                        </div>
                        <Row className="acuverse-structure">
                            <Col md={3} xl={2}>
                                <Row className="flex-column">
                                    <Col className="item pos-lt">
                                        <h3>METAVERSE</h3>
                                        <img src={require('./../assets/images/metaverse.png')} alt="Metaverse" />
                                        <p>Controls data footprint, integrity, use, purpose, flow, and quality delivery</p>
                                    </Col>
                                    <Col className="item pos-lb">
                                        <h3>SECURVERSE</h3>
                                        <img src={require('./../assets/images/securverse.png')} alt="Securverse" />
                                        <p>Governs what data needs protection and who has what access to enterprise data</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} xl={4}>
                                <h2>ACUVERSE</h2>
                                <div className="acuverse-center">
                                    <img src={require('./../assets/images/acuverse-logo.png')} alt="Acuverse" />
                                </div>
                            </Col>
                            <Col md={3} xl={2}>
                                <Row className="flex-column">
                                    <Col className="item pos-rt">
                                        <h3>NEXVERSE</h3>
                                        <img src={require('./../assets/images/nexverse.png')} alt="Nexverse" />
                                        <p>Enables dynamic data discovery and delivers information at 3x speed</p>
                                    </Col>
                                    <Col className="item pos-rb">
                                        <h3>INTELVERSE</h3>
                                        <img src={require('./../assets/images/intelverse.png')} alt="Intelverse" />
                                        <p>Improves performance, and executes strategy with decision support systems</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container fluid>
                        <div className="section-title">
                            <h2>FUNCTIONAL ARCHITECTURE</h2>
                            <h3>Emerging Technologies</h3>
                            <p>Athena is a single, modular, scalable platform that consumes and analyzes data,and learns from the trends to autonomously make unbiased decisions</p>
                        </div>
                        <div className="text-center"><img src={require('./../assets/images/functional-architecture.png')} className='img-fluid' width="1000" alt="Acuverse Functional Architecture" /></div>
                    </Container>
                </section>
                <section>
                    <Container fluid>
                        <div className="section-title">
                            <h2>INNOVATION ECOSYSTEM</h2>
                            <h3>Adapt The Future</h3>
                            <p>We harness the potential of AI, Data Mesh, Knowledge Graph, Active Metadata and Autonomous Technologies to build future-ready capabilities that solve real world problems</p>
                        </div>
                        <div className="acuverse-details">
                            <Row xs={1} sm={2} lg={4}>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/metaverse-small.png')} width="70" alt="Metaverse" /></span>
                                            <span className="text">METAVERSE</span>
                                        </div>
                                        <div className="flip-card-back">
                                            <p><span className="txt-orange">METAVERSE</span> defines and contains the data foot-print with data ontology to control data integrity and optimize data use, purpose, flow, and quality delivery.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/nexverse-small.png')} width="70" alt="Nexverse" /></span>
                                            <span className="text">NEXVERSE</span>
                                        </div>
                                        <div className="flip-card-back">
                                            <p><span className="txt-orange">NEXVERSE</span> contains data, controls data nexus, autonomously discovering data and data patterns, to build a dynamic data fabric and control dynamic data delivering</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/intelverse-small.png')} width="70" alt="Intelverse" /></span>
                                            <span className="text">INTELVERSE</span>
                                        </div>
                                        <div className="flip-card-back">
                                            <p><span className="txt-orange">INTELVERSE</span> derives business intelligence to analyze and predict business performance, and execute business strategy with decision support systems</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flip-card">
                                    <div className="flip-card-inner" >
                                        <div className="flip-card-front">
                                            <span className="image"><img src={require('./../assets/images/securverse-small.png')} width="70" alt="Securverse" /></span>
                                            <span className="text">SECURVERSE</span>
                                        </div>
                                        <div className="flip-card-back">
                                            <p><span className="txt-orange">SECURVERSE</span> embraces data privacy, defines data that needs protection, controls enterprise data flow, and governs who has what access to enterprise data</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer />
            <StickyIcons isEntrera={true} isDoorway={true} />
        </>
    );
}
 
export default Acuverse;