import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Header from '../components/header';
import Footer from '../components/footer';
import StickyIcons from '../components/stickyIcons';
import PagetTitle from "../components/PageTitle";
import Ticker from "../components/Ticker";

function Analytics() {
    const location = useLocation();

    const handleDemo = (event)=> {
        let requestdemo = document.getElementById('Athena_Demo_Form');
        localStorage.setItem('buttoncontext',event.target.dataset.context);
        requestdemo.click();
    }

    /* DE data -start */
    const de_data = [
        { image: "OpsRiskSurveillance", text: "Operations Risk Surveillance" },
        { image: "cust-CompliantMgmt", text: "Customer Complaint Management" },
        { image: "CustSatisfaction", text: "Customer Satisfaction" },
        { image: "dataQuality", text: "Data Quality Management" },
        { image: "DataSecurity", text: "Data Security Surveillance" },
        { image: "rrm", text: "Reputation Risk Management" },
        { image: "performanceMgmt", text: "Performance Management" },
        { image: "CallOfDissatisfaction", text: "Cost Of Dissatisfaction" },
        { image: "CustVoice", text: "Customer Voice" },
        { image: "Prescriptive-Maintenance", text: "Prescriptive Maintenance" },
        { image: "Operations-Surveillance", text: "Operations Surveillance" },
        { image: "Product-Personalization", text: "Product Personalization" },
        { image: "Customer-Segmentation", text: "Customer Segmentation" },
        { image: "Customer-Acquisition", text: "Customer Acquisition" },
        { image: "Resource-Optimization", text: "Resource Optimization" },
        { image: "sfe", text: "Sales Force Effectiveness" },
        { image: "Load-Factor-Management", text: "Load Factor Management" },
        { image: "Route-Optimization", text: "Route Optimization" },
        { image: "Campaign-Management", text: "Campaign Management" },
        { image: "loanUnderwriting", text: "Loan Underwriting" },
        { image: "Data-Risk-Classification", text: "Data Risk Classification" },
        { image: "collections", text: "Repayment Risks" },
        { image: "Personalized-Product-Processor", text: "Personalized Product Processor" },
        { image: "Dynamic-Pricing", text: "Dynamic Pricing" },
        { image: "hacc", text: "Hospital Acquired Conditions Control" },
        { image: "Readmission-Risk", text: "Readmission Risk" },
    ];

    function shuffle(array) {
        var ctr = array.length,
          temp,
          index;
        while (ctr > 0) {
          index = Math.floor(Math.random() * ctr);
          ctr--;
          temp = array[ctr];
          array[ctr] = array[index];
          array[index] = temp;
        }
        return array;
      }

    const [de_list, setDe_list] = useState([]);
    useEffect(() => {
        setDe_list(shuffle(de_data));
    }, []);
    /* DE data -end */

    const [industryKey, setIndustryKey] = useState("retail");
    const handleSelectIndustryKey = (industryKey) => {
        setIndustryKey(industryKey);
    }
    useEffect(() => {
        if(location.state){
            //console.log(location);
            setIndustryKey(location.state);
            setTimeout(() => {
                document.getElementById("industry").scrollIntoView();
            }, 300);
        }
        if (window.performance) {
            if (performance.navigation.type == 1) {
                window.history.replaceState({}, document.title);
                //console.log(location);
            }
          }
    }, []);

    return (
        <>
            <Header isentrera={true} isacuverse={true} isdoorway={true}/>
            <PagetTitle title="Athena | Analytics"/>
            <main>
                <section className="saas bg" style={{ backgroundImage: `url(${require("./../assets/images/saas-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h1>ANALYTICS-AS-A-SERVICE</h1>
                            <h3>Power Your Business Growth</h3>
                            <p>Athena Analytics Platform helps make better decisions, faster, in half the time and one-third the cost to maximize your company's RoI.</p>
                        </div>
                        <Row className="icon-details-list">
                            <Col>
                                <div className="image"><img src={require('./../assets/images/data-management.png')} width="50" alt="Data Management" /></div>
                                <h3>Data Management</h3>
                                <ul className="p-0">
                                    <li>
                                        <span className="percentage"><Ticker end={90}/>%</span>
                                        <span className="text">Productivity in data management</span>
                                    </li>
                                    <li>
                                        <span className="percentage"><Ticker end={70}/>%</span>
                                        <span className="text">Data management cost optimization</span>
                                    </li>
                                    <li>
                                        <span className="percentage"><Ticker end={30}/>%</span>
                                        <span className="text">Data privacy and security cost optimization</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <div className="image"><img src={require('./../assets/images/business-intelligence.png')} width="50" alt="Business Intelligence" /></div>
                                <h3>Business Intelligence</h3>
                                <ul className="p-0">
                                    <li>
                                        <span className="percentage"><Ticker end={300}/>%+</span>
                                        <span className="text">Increase in integrated Data delivery</span>
                                    </li>
                                    <li>
                                        <span className="percentage"><Ticker end={30}/>%+</span>
                                        <span className="text">Increase in business productivity</span>
                                    </li>
                                    <li>
                                        <span className="percentage"><Ticker end={50}/>%+</span>
                                        <span className="text">Operations efficiency, driving up performance</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <div className="image"><img src={require('./../assets/images/decision-engine.png')} width="60" alt="Decision Engine" /></div>
                                <h3>Decision Engine</h3>
                                <ul className="p-0">
                                    <li>
                                        <span className="percentage"><Ticker end={15}/>%+</span>
                                        <span className="text">Growth in revenues with market strategies</span>
                                    </li>
                                    <li>
                                        <span className="percentage"><Ticker end={30}/>%+</span>
                                        <span className="text">Optimization in cost of doing business</span>
                                    </li>
                                    <li>
                                        <span className="percentage"><Ticker end={70}/>%+</span>
                                        <span className="text">Productivity in enterprise data management</span>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        {/* <div className="bottom-text"><p>C-suite stakeholders andor people in their functions who are responsible for making business decisions.</p></div> */}
                    </Container>
                </section>
                <section className="stakeholders">
                    <Container fluid>
                        <div className="section-title">
							<h2>INSPIRING STRATEGY</h2>
							<h3>Solve Your Problems</h3>
                            <p>Athena knows your market, your industry, and your business functions, and is here to offer intelligence and decisions to C-suite stakeholders, functional heads, and business decision - makers</p>
                        </div>
                        <Row xs={1} md={2} lg={4}>
                            <Col className="item">
                                <div className="image">
                                    <img src={require('./../assets/images/strategy-owners.png')} className="img-responsive" alt="Strategy Owners" />
                                    <div className="details">
                                        <div className="image"><img src={require('./../assets/images/strategy-owners-icon.png')} width="42" alt="Strategy Owners" /></div>
                                        <h3>Strategy Owners</h3>
                                        <ul>
                                            <li>Chief Executive Officer</li>
                                            <li>Chief Finance Officer</li>
                                            <li>Chief Strategy Officer</li>
                                        </ul>
                                    </div>
                                    <div className="overlay">
                                        <h3>Strategy Owners</h3>
                                        <ul>
                                            <li>Rising customer churn and or eroding customer trust</li>
                                            <li>Big picture is missing, business value unknown</li>
                                            <li>Missed growth opportunities</li>
                                            <li>Business fails to realize data potential, value</li>
                                            <li>Low or no efficiency, productivity, and low or negative RoI</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="image">
                                    <img src={require('./../assets/images/growth-owners.png')} className="img-responsive" alt="Growth Owners" />
                                    <div className="details">
                                        <div className="image"><img src={require('./../assets/images/growth-owners-icon.png')} width="50" alt="Growth Owners" /></div>
                                        <h3>Growth Owners</h3>
                                        <ul>
                                            <li>Chief Marketing Officer</li>
                                            <li>Chief Sales Officer</li>
                                            <li>Chief Growth Officer</li>
                                        </ul>
                                    </div>
                                    <div className="overlay">
                                        <h3>Growth Owners</h3>
                                        <ul>
                                            <li>Missed opportunities for driving growth</li>
                                            <li>Poorly targeted marketing campaigns</li>
                                            <li>Declining reputation and customer satisfaction</li>
                                            <li>Unable to create new customers</li>
                                            <li>Rising fear of losing valuable customer data</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="image">
                                    <img src={require('./../assets/images/operations-owners.png')} className="img-responsive" alt="Operations Owners" />
                                    <div className="details">
                                        <div className="image"><img src={require('./../assets/images/operations-owners-icon.png')} width="50" alt="Operations Owners" /></div>
                                        <h3>Operations Owners</h3>
                                        <ul>
                                            <li>Chief Operating Officer</li>
                                            <li>Chief Digital Officer</li>
                                            <li>Chief Technology Officer</li>
                                        </ul>
                                    </div>
                                    <div className="overlay">
                                        <h3>Operations Owners</h3>
                                        <ul>
                                            <li>Low or no opportunities for cost savings, optimization</li>
                                            <li>Data integration projects continue to fail</li>
                                            <li>Rising cost of data and information management</li>
                                            <li>Trapped in traditional product, tool, technology investments</li>
                                            <li>Low or no return on investment in analytics</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="image">
                                    <img src={require('./../assets/images/information-owners.png')} className="img-responsive" alt="Information Owners" />
                                    <div className="details">
                                        <div className="image"><img src={require('./../assets/images/information-owners-icon.png')} width="50" alt="Information Owners" /></div>
                                        <h3>Information Owners</h3>
                                        <ul>
                                            <li>Chief Information Officer</li>
                                            <li>Chief Data Officer</li>
                                            <li>Chief Security Officer</li>
                                        </ul>
                                    </div>
                                    <div className="overlay">
                                        <h3>Information Owners</h3>
                                        <ul>
                                            <li>Failing to operationalize analytics to drive business value</li>
                                            <li>Rising threats of data security and privacy</li>
                                            <li>Rising data integrity management costs</li>
                                            <li>No business trust in intelligence or insights</li>
                                            <li>Information resides in multiple products, platforms</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="industry" className="our-offerings">
                    <Container fluid>
                        <div className="section-title">
							<h2>INDUSTRY OFFERINGS</h2>
                            <h3>Who We Serve</h3>
                        </div>
                        <Tabs activeKey={industryKey} onSelect={handleSelectIndustryKey}>
                            <Tab eventKey="retail" title="Retail, CPG &amp; E-Com">
                                <Row>
                                    <Col lg={9} className="business-intelligence">
                                        <h4>BUSINESS INTELLIGENCE</h4>
                                        <Row xs={2}>
                                            <Col>
                                                <ul>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/marketing.png')} alt="Marketing" /></span>
                                                        <span className="text">Marketing</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Brand Awareness &amp; Effectiveness</li>
                                                                <li>Omni Channel Customer Voice</li>
                                                                <li>Marketing Mix ROI</li>
                                                                <li>Trade Promotion Effectiveness</li>
                                                                <li>Competitor Intelligence</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/sales.png')} alt="Sales" /></span>
                                                        <span className="text">Sales</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Product Category Performance</li>
                                                                <li>Product price Effectiveness</li>
                                                                <li>Distribution Network Performance</li>
                                                                <li>Margin &amp; Profitability Performance</li>
                                                                <li>Pricing Strategy Effectiveness</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/manufacturing.png')} alt="Manufacturing" /></span>
                                                        <span className="text">Manufacturing</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Product Forecasting Optimization</li>
                                                                <li>Production Throughput &amp; Efficiency</li>
                                                                <li>Workforce Safety &amp; Incident Optimization</li>
                                                                <li>Equipment &amp; Asset Performance</li>
                                                                <li>Production Performance &amp; Quality Effectiveness</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/logistics.png')} alt="Logistics" /></span>
                                                        <span className="text">Logistics</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Location Presence Effectiveness</li>
                                                                <li>Inventory Transit Effectiveness &amp; Reverse Logistics</li>
                                                                <li>Resource &amp; Route Optimization</li>
                                                                <li>Supply Chain Performance Optimization</li>
                                                                <li>Order Fulfilment Effectiveness</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col>
                                                <ul>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/portfolio.png')} alt="Portfolio" /></span>
                                                        <span className="text">Portfolio</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Product Category &amp; Portfolio Performance</li>
                                                                <li>Resource Performance &amp; Effectiveness</li>
                                                                <li>Portfolio Planning Effectiveness</li>
                                                                <li>Program Performance &amp; Effectiveness</li>
                                                                <li>Portfolio Risk Performance</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/workforce.png')} alt="Workforce" /></span>
                                                        <span className="text">Workforce</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Skills Readiness &amp; Effectiveness</li>
                                                                <li>Talent Performance &amp; Effectiveness</li>
                                                                <li>Critical Talent Learning Performance</li>
                                                                <li>Workforce Segment &amp; Value Creation Effectiveness</li>
                                                                <li>Workforce Planning Effectiveness</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/sustainability.png')} alt="Sustainability" /></span>
                                                        <span className="text">Sustainability</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Price &amp; Supply Volatility Trends</li>
                                                                <li>Environment &amp; Health Impact trends</li>
                                                                <li>Supplier Sustainability Performance</li>
                                                                <li>Product Category Prioritization</li>
                                                                <li>Product &amp; Resource Cost Optimization</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/finance.png')} alt="Finance" /></span>
                                                        <span className="text">Finance</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Working Capital Management Effectiveness</li>
                                                                <li>Spend &amp; Procurement Effectiveness</li>
                                                                <li>Vendor Performance &amp; Spend Leakages</li>
                                                                <li>Procurement Cost Effectiveness</li>
                                                                <li>Profitability &amp; Growth Potential</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} className="decision-engines">
                                        <h4>DECISION ENGINES</h4>
                                        <ul>
                                            <li>
                                                <img src={require('./../assets/images/crossSell.png')} width="70" alt="Cross Sell &amp; Up Sell" />
                                                <span>Cross Sell <br/>&amp; Up Sell</span>
                                                <div className="overlay">
                                                    <p>Generates sales recognizing the right product for the right customer, at the right price, at the right time.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/custAdvocacy.png')} width="70" alt="Customer Advocacy" />
                                                <span>Customer <br/>Advocacy</span>
                                                <div className="overlay">
                                                    <p>Recognizes voicers and their influence on the brand to resolve problems and create brand advocate</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/sfe.png')} width="70" alt="Sales force Effectiveness" />
                                                <span>Sales force <br/>Effectiveness</span>
                                                <div className="overlay">
                                                    <p>Optimizes customer engagement, engagement mode, and customer relations planning processes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/rrm.png')} width="70" alt="Reputation Risk Management" />
                                                <span>Reputation Risk <br/>Management</span>
                                                <div className="overlay">
                                                    <p>Ranks the online reputation of the brand and prescribes customer communication strategies.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="financial-services" title="Financial Services">
                                <Row>
                                    <Col lg={9} className="business-intelligence">
                                        <h4>BUSINESS INTELLIGENCE</h4>
                                        <Row xs={2}>
                                            <Col>
                                                <ul>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/sales.png')} alt="Sales" /></span>
                                                        <span className="text">Sales</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Sales Team Performance</li>
                                                                <li>Month-on-Month Target Performance</li>
                                                                <li>Financial Year Performance Trends</li>
                                                                <li>Sales Turn Around Time</li>
                                                                <li>Pipeline Growth &amp; Conversion</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/loanUnderwriting.png')} alt="Credit" /></span>
                                                        <span className="text">Credit</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Portfolio Performance</li>
                                                                <li>Underwriting Trends &amp; Patterns</li>
                                                                <li>Credit Risk Trends</li>
                                                                <li>Loan Repayment Trnds</li>
                                                                <li>Underwriting Exception &amp; Turn Around Time</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/technical.png')} alt="Technical" /></span>
                                                        <span className="text">Technical</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Case Count &amp; Status</li>
                                                                <li>Valuation Variance</li>
                                                                <li>Vendor Performance &amp; Trends</li>
                                                                <li>Technical Exception</li>
                                                                <li>Turn Around Time</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/operations.png')} alt="Operations" /></span>
                                                        <span className="text">Operations</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Branch Operations Performance</li>
                                                                <li>Monthly Operations Trends &amp; Performance</li>
                                                                <li>Exception Review &amp; Performance</li>
                                                                <li>Vendor Performance &amp; Assessment</li>
                                                                <li>Turn Around Time</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col>
                                                <ul>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/risk.png')} alt="Risk" /></span>
                                                        <span className="text">Risk</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Risk Segment Performance</li>
                                                                <li>Customer Segment Performance</li>
                                                                <li>Repayment Negligence Trends</li>
                                                                <li>Regional Risk Strategies</li>
                                                                <li>Regional Customer Strategies</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/collections.png')} alt="Collections" /></span>
                                                        <span className="text">Collections</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Collections Summary</li>
                                                                <li>Collections trends (EMIs &amp; Charges)</li>
                                                                <li>Deliquency Trends</li>
                                                                <li>Bounce Trends</li>
                                                                <li>Month-on-Book Performance</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/accounting.png')} alt="Accounting" /></span>
                                                        <span className="text">Accounting</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Disbursement Performance</li>
                                                                <li>Month-on-Month Growth Target Performance</li>
                                                                <li>NPA Provisions</li>
                                                                <li>Pre-Closure Impacts</li>
                                                                <li>Reschedule &amp; Reconstruction Status</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/finance.png')} alt="Finance" /></span>
                                                        <span className="text">Finance</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Cost Center Performance</li>
                                                                <li>OPEX vs. Non-OPEX Trends</li>
                                                                <li>Branch Level Financial Performance</li>
                                                                <li>Profitability &amp; Growth Potential</li>
                                                                <li>NIM Trends &amp; Patterns</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} className="decision-engines">
                                        <h4>DECISION ENGINES</h4>
                                        <ul>
                                            <li>
                                                <img src={require('./../assets/images/loanUnderwriting.png')} width="70" alt="Loan Underwriting" />
                                                <span>Loan <br/>Underwriting</span>
                                                <div className="overlay">
                                                    <p>Learns risk policy and portfolio performance to right price risk and underwrite, sanction, loans.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/crossSell.png')} width="70" alt="Cross Sell &amp; Up Sell" />
                                                <span>Cross Sell <br/>&amp; Up Sell</span>
                                                <div className="overlay">
                                                    <p>Generates new leads for the right product for the right customer, at the right price, at the right time.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/collectionsEffectiveness.png')} width="70" alt="Collections Effectiveness" />
                                                <span>Collections <br/>Effectiveness</span>
                                                <div className="overlay">
                                                    <p>Recognizes repayment behavior and optimizes debt collection resources, communication, efforts and costs.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/creditRiskMgmt.png')} width="70" alt="Credit Risk Management" />
                                                <span>Credit Risk <br/>Management</span>
                                                <div className="overlay">
                                                    <p>Recognizes credit risk threats and prescribes risk policy and product price change strategies.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="insurance" title="Insurance">
                                <Row>
                                    <Col lg={9} className="business-intelligence">
                                        <h4>BUSINESS INTELLIGENCE</h4>
                                        <Row xs={2}>
                                        <Col>
                                                <ul>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/sales.png')} alt="Sales" /></span>
                                                        <span className="text">Sales</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Sales Team Performance</li>
                                                                <li>Month-on-Month Target Performance</li>
                                                                <li>Financial Year Performance Trends</li>
                                                                <li>Sales Channel Turn Around Time</li>
                                                                <li>Pipeline Growth &amp; Conversion</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/actuarial.png')} alt="Actuarial" /></span>
                                                        <span className="text">Actuarial</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Product Performance</li>
                                                                <li>Portfolio Performance</li>
                                                                <li>Product &amp; Portfolio Risk Trends</li>
                                                                <li>New Product Development Strategies</li>
                                                                <li>Product Risk Pricing Strategies</li>

                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/consumer.png')} alt="Consumer" /></span>
                                                        <span className="text">Consumer</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Customer Segments</li>
                                                                <li>Sales Channel Performance</li>
                                                                <li>Campaign Performance Trends</li>
                                                                <li>Campaign Management Strategies</li>
                                                                <li>Product Marketing Strategies</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/fraud.png')} alt="Fraud" /></span>
                                                        <span className="text">Fraud</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Fraud Claim Counts</li>
                                                                <li>Fraud Claim Customer Behaviors</li>
                                                                <li>Fraud Claim Product Behaviors</li>
                                                                <li>Fraud Detection Strategies</li>
                                                                <li>Fraud Prevention &amp; Control Strategies</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col>
                                                <ul>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/loanUnderwriting.png')} alt="Underwriting" /></span>
                                                        <span className="text">Underwriting</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Underwriting Trends &amp; Patterns</li>
                                                                <li>Product Profitability Performance</li>
                                                                <li>Underwriting Exception &amp; Turn Around Time</li>
                                                                <li>Regional Risk Strategies</li>
                                                                <li>Regional Customer Strategies</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/claims.png')} alt="Claims" /></span>
                                                        <span className="text">Claims</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Claims Turnaround Performance</li>
                                                                <li>Claims Processing Trends</li>
                                                                <li>Claims Operations &amp; Cost Trends</li>
                                                                <li>Claims Policy Compliance Breaches</li>
                                                                <li>Claims Personalization Strategies</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/accounting.png')} alt="Accounting" /></span>
                                                        <span className="text">Accounting</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Disbursement Performance</li>
                                                                <li>Month-on-Month Growth Target Performance</li>
                                                                <li>NPA Provisions</li>
                                                                <li>Pre-Closure Impacts</li>
                                                                <li>Reschedule &amp; Reconstruction Status</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/finance.png')} alt="Finance" /></span>
                                                        <span className="text">Finance</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Cost Center Performance</li>
                                                                <li>OPEX vs. Non-OPEX Trends</li>
                                                                <li>Branch Level Financial Performance</li>
                                                                <li>Profitability &amp; Growth Potential</li>
                                                                <li>NIM Trends &amp; Patterns</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} className="decision-engines">
                                        <h4>DECISION ENGINES</h4>
                                        <ul>
                                            <li>
                                                <img src={require('./../assets/images/loanUnderwriting.png')} width="70" alt="Premium Underwriting" />
                                                <span>Premium <br/>Underwriting</span>
                                                <div className="overlay">
                                                    <p>Learns from claims and product profitability performance to right price insurance product premiums.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/crossSell.png')} width="70" alt="Cross Sell &amp; Up Sell" />
                                                <span>Cross Sell <br/>&amp; Up Sell</span>
                                                <div className="overlay">
                                                    <p>Generates new leads for the right product for the right customer, at the right price, at the right time.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/custAdvocacy.png')} width="70" alt="Customer Advocacy" />
                                                <span>Customer <br/>Advocacy</span>
                                                <div className="overlay">
                                                    <p>Recognizes change in product trends to review, resolve, sanction claims and build brand advocates.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/claimsEffectiveness.png')} width="50" alt="Claim Effectiveness" />
                                                <span>Claim <br/>Effectiveness</span>
                                                <div className="overlay">
                                                    <p>Reviews, approves, and processes insurance claims within policy limits effectively, and inexpensively.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="healthcare" title="Healthcare">
                                <Row>
                                    <Col lg={9} className="business-intelligence">
                                        <h4>BUSINESS INTELLIGENCE</h4>
                                        <Row xs={2}>
                                            <Col>
                                                <ul>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/care.png')} alt="Care" /></span>
                                                        <span className="text">Care</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Care Hours Performance</li>
                                                                <li>Nursing Care Quality Performance</li>
                                                                <li>Patient Retention Rate</li>
                                                                <li>Care Complication Rate</li>
                                                                <li>Post Procedural Death Rate</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/service.png')} alt="Service" /></span>
                                                        <span className="text">Service</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Arrival to Discharge Performance</li>
                                                                <li>Wait Time Effectiveness</li>
                                                                <li>Unattended Visitor rate</li>
                                                                <li>Rapid Response Rate</li>
                                                                <li>Patient Satisfaction rate</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/safety.png')} alt="Safety" /></span>
                                                        <span className="text">Safety</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Care Outcome Performance</li>
                                                                <li>Surgical Wound Infection</li>
                                                                <li>Inpatient Mortality Rate</li>
                                                                <li>Hospital Facility Incident Rate</li>
                                                                <li>Equipment &amp; Transport Service Performance</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/operations.png')} alt="Operations" /></span>
                                                        <span className="text">Operations</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Bed &amp; Room Turnover</li>
                                                                <li>Occupancy Rate</li>
                                                                <li>Average Length of Stay</li>
                                                                <li>Discharge Process Time</li>
                                                                <li>Equipment Utilization Rate</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col>
                                                <ul>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/risk-2.png')} alt="Risk" /></span>
                                                        <span className="text">Risk</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Readmission Rate</li>
                                                                <li>Hospital Acquired Conditions</li>
                                                                <li>Hazardous Materials Usage</li>
                                                                <li>Code Response Rate</li>
                                                                <li>Equipment Failure Rate</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/complaince.png')} alt="Compliance" /></span>
                                                        <span className="text">Compliance</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Patient Confidentiality Breaches</li>
                                                                <li>Complaint Resolution Rate</li>
                                                                <li>Public Health Program Performance</li>
                                                                <li>Care Quality &amp; Standard Effectiveness</li>
                                                                <li>Investigation &amp; Remedial Measure Effectiveness</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/marketing.png')} alt="Marketing" /></span>
                                                        <span className="text">Marketing</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Campaign &amp; Catchment Performance</li>
                                                                <li>Brand Presence &amp; Performance</li>
                                                                <li>Population Diversity Performance</li>
                                                                <li>Digital Footprint &amp; Engagement Performance</li>
                                                                <li>Contact Centre Performance</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="image"><img src={require('./../assets/images/finance.png')} alt="Finance" /></span>
                                                        <span className="text">Finance</span>
                                                        <div className="overlay">
                                                            <ul>
                                                                <li>Discharge Cost Performance Trends</li>
                                                                <li>Revenue Leakages</li>
                                                                <li>Claims Denial Rate</li>
                                                                <li>OPEX vs. Non-OPEX Trends</li>
                                                                <li>Profitability &amp; Growth Potential</li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} className="decision-engines">
                                        <h4>DECISION ENGINES</h4>
                                        <ul>
                                            <li>
                                                <img src={require('./../assets/images/patientBill.png')} width="70" alt="Patient Bill Estimation" />
                                                <span>Patient Bill <br/>Estimation</span>
                                                <div className="overlay">
                                                    <p>Recognizes patient care and ongoing health issues to prescribe 90%+ accurate patient bill estimates.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/assetUtilizationOptimize.png')} width="70" alt="Asset Utilization Optimization" />
                                                <span>Asset Utilization <br/>Optimization</span>
                                                <div className="overlay">
                                                    <p>Learns from hospital asset utilization trends to optimize asset allocation and patient care journey.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/survellience.png')} width="70" alt="Operations Surveillance" />
                                                <span>Operations <br/>Surveillance</span>
                                                <div className="overlay">
                                                    <p>Learns from changing hospital trends to monitor and control hospital operations processes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={require('./../assets/images/readmissionRisk.png')} width="70" alt="Readmission Risk Management" />
                                                <span>Readmission Risk <br/>Management</span>
                                                <div className="overlay">
                                                    <p>Recognizes patient readmission risks to prescribe care process and patient care journey.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Container>
                </section>
                <section className="saas-subscription">
                    <Container fluid>
                        <div className="section-title">
                            <h2>ANALYTICS SUBSCRIPTION</h2>
                            <h3>How We Serve</h3>
                            <p>Plug n Play modules for decision makers available on pay per month subscription</p>
                        </div>
                        <Row xs={1} sm={2}>
                            <Col className="item">
                                <div className="inner">
                                    <h4>BUSINESS INTELLIGENCE</h4>
                                    <span className="image"><img src={require('./../assets/images/bi.png')} width="90" alt="BUSINESS INTELLIGENCE" /></span>
                                    <ul>
                                        <li>Intelligence-as-a-Service</li>
                                        <li>Pay Per Module</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <h4>DECISION ENGINES</h4>
                                    <span className="image"><img src={require('./../assets/images/de.png')} width="100" alt="Decision Engines" /></span>
                                    <ul>
                                        <li>Decision-as-a-Service</li>
                                        <li>Pay Per Decision</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="business-intelligence">
                    <Container fluid>
                        <div className="section-title">
							<h2>CAPABILITIES AND CREDENTIALS</h2>
                            <h3>Business Intelligence</h3>
							<p>Athena consumes and analyzes your data to bring you unbiased intelligence on business performance to inform your business strategy.</p>
                        </div>
                        <div className="graphs">
                        <Row lg={3} md={2} xs={1}>
                                                            <Col>
                                    <div className="inner">
                                        <span className="image"><img src={require('./../assets/images/sales-cp.png')} alt="Sales Conversion Pipeline" className="img-responsive" /></span>
                                        <span className="details">
                                            <span className="text">Sales Conversion Pipeline</span>
                                            <button className="btn-yellow" onClick={handleDemo} data-context="Sales Conversion Pipeline">REQUEST DEMO</button>
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="inner">
                                        <span className="image"><img src={require('./../assets/images/supply-cp.png')} alt="Supply Chain Performance" className="img-responsive" /></span>
                                        <span className="details">
                                            <span className="text">Supply Chain Performance</span>
                                            <button className="btn-yellow" onClick={handleDemo} data-context="Supply Chain Performance">REQUEST DEMO</button>
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="inner">
                                        <span className="image"><img src={require('./../assets/images/product-opt.png')} alt="Production Optimization" className="img-responsive" /></span>
                                        <span className="details">
                                            <span className="text">Production Optimization</span>
                                            <button className="btn-yellow" onClick={handleDemo} data-context="Production Optimization">REQUEST DEMO</button>
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="inner">
                                        <span className="image"><img src={require('./../assets/images/cod.png')} alt="Cost of Dissatisfaction" className="img-responsive" /></span>
                                        <span className="details">
                                            <span className="text">Cost of Dissatisfaction</span>
                                            <button className="btn-yellow" onClick={handleDemo} data-context="Cost of Dissatisfaction">REQUEST DEMO</button>
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="inner">
                                        <span className="image"><img src={require('./../assets/images/ppv.png')} alt="Portfolio &amp; Price Volatility" className="img-responsive" /></span>
                                        <span className="details">
                                            <span className="text">Portfolio &amp; Price Volatility</span>
                                            <button className="btn-yellow" onClick={handleDemo} data-context="Portfolio &amp; Price Volatility">REQUEST DEMO</button>
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="inner">
                                        <span className="image"><img src={require('./../assets/images/mp.png')} alt="Manufacturing Productivity" className="img-responsive" /></span>
                                        <span className="details">
                                            <span className="text">Manufacturing Productivity</span>
                                            <button className="btn-yellow" onClick={handleDemo} data-context="Manufacturing Productivity">REQUEST DEMO</button>
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="inner">
                                        <span className="image"><img src={require('./../assets/images/dpf.png')} alt="Demand Planning &amp; Fulfilment" className="img-responsive" /></span>
                                        <span className="details">
                                            <span className="text">Demand Planning &amp; Fulfilment</span>
                                            <button className="btn-yellow" onClick={handleDemo} data-context="Demand Planning &amp; Fulfilment">REQUEST DEMO</button>
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="inner">
                                        <span className="image"><img src={require('./../assets/images/pp.png')} alt="Portfolio Performance" className="img-responsive" /></span>
                                        <span className="details">
                                            <span className="text">Portfolio Performance</span>
                                            <button className="btn-yellow" onClick={handleDemo} data-context="Portfolio Performance">REQUEST DEMO</button>
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="inner">
                                        <span className="image"><img src={require('./../assets/images/mps.png')} alt="Market Penetration Strategies" className="img-responsive" /></span>
                                        <span className="details">
                                            <span className="text">Market Penetration Strategies</span>
                                            <button className="btn-yellow" onClick={handleDemo} data-context="Market Penetration Strategies">REQUEST DEMO</button>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <section className="capabilities-credentials">
                    <Container fluid>
                        <div className="section-title">
                            <h2>CAPABILITIES AND CREDENTIALS</h2>
                            <h3>Decision Engine</h3>
                        </div>
                        <div className="blocks">
                            <Row xs={1} sm={2} lg={3}>
                                {de_list.slice(0, 9).map((item, index) => (
                                    <Col key={index}>
                                        <div className="inner">
                                            <span className="image"><img src={require(`./../assets/images/${item.image}.png`)} alt={item.text} /></span>
                                            <span className="text">{item.text}</span>
                                            <span className="overlay"><button className="btn-yellow" onClick={handleDemo} data-context={item.text}>REQUEST DEMO</button></span>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Container>
                </section>
                <section className="enterprise-dm">
                    <Container fluid>
                        <div className="section-title">
							<h2>CAPABILITIES AND CREDENTIALS</h2>
                            <h3>Enterprise Data Management</h3>
							<p>Athena reduces the data footprint, controls data integrity, and manages data privacy and security, across systems, to optimize your cost of enterprise data management.</p>
                        </div>
                        <div className="blocks-BI-DE">
                            <Row>
                                <Col lg={9} className="business-intelligence">
                                    <h4>DATA MANAGEMENT SERVICES</h4>
                                    <Row xs={2}>
                                        <Col>
                                        <ul>
                                            <li>
                                                <span className="image"><img src={require('./../assets/images/dsm.png')} alt="Data Security Management" /></span>
                                                <span className="text">Data Security Management</span>
                                            </li>
                                            <li>
                                                <span className="image"><img src={require('./../assets/images/drc.png')} alt="Data Risk Classification" /></span>
                                                <span className="text">Data Risk Classification</span>
                                            </li>
                                            <li>
                                                <span className="image"><img src={require('./../assets/images/mdm.png')} alt="Meta Data Management" /></span>
                                                <span className="text">Meta Data Management</span>
                                            </li>
                                            <li>
                                                <span className="image"><img src={require('./../assets/images/dwm.png')} alt="Data Warehouse Management" /></span>
                                                <span className="text">Data Warehouse Management</span>
                                            </li>
                                        </ul>
                                        </Col>
                                        <Col>
                                            <ul>
                                                <li>
                                                    <span className="image"><img src={require('./../assets/images/dlp.png')} alt="Data Loss Prevention" /></span>
                                                    <span className="text">Data Loss Prevention</span>
                                                </li>
                                                <li>
                                                    <span className="image"><img src={require('./../assets/images/dcm.png')} alt="Data Change Management" /></span>
                                                    <span className="text">Data Change Management</span>
                                                </li>
                                                <li>
                                                    <span className="image"><img src={require('./../assets/images/master-dm.png')} alt="Master Data Management" /></span>
                                                    <span className="text">Master Data Management</span>
                                                </li>
                                                <li>
                                                    <span className="image"><img src={require('./../assets/images/dlm.png')} alt="Data Lifecycle Management" /></span>
                                                    <span className="text">Data Lifecycle Management</span>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3} className="decision-engines">
                                    <h4>SURVEILLANCE ENGINES</h4>
                                    <ul>
                                        <li>
                                            <img src={require('./../assets/images/dataQuality.png')} width="70" alt="Data Quality" />
                                            <span>Data <br/>Quality</span>
                                            <div className="overlay">
                                                <p>Monitors and Controls data integrity and reports systems and processes responsible for poor data quality</p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/dataLIfecycle.png')} width="70" alt="Data LIfecycle" />
                                            <span>Data <br/>Lifecycle</span>
                                            <div className="overlay">
                                                <p>Manages secure data storage and revival strategies in compliance with enterprise data lifecycle policies.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/masterData.png')} width="70" alt="Master Data" />
                                            <span>Master <br/>Data</span>
                                            <div className="overlay">
                                                <p>Creates, secures, and controls critical master data generated by enterprise systems and processes.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={require('./../assets/images/dataChange.png')} width="70" alt="Data Change" />
                                            <span>Data <br/>Change</span>
                                            <div className="overlay">
                                                <p>Monitors and controls meta data and master data changes to preserve a single version of the truth.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer />
            <StickyIcons isEntrera={true} isAcuverse={true} isDoorway={true} />
        </>
    );
}
 
export default Analytics;