import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/header';
import Footer from '../components/footer';
import StickyIcons from '../components/stickyIcons';
import PagetTitle from "../components/PageTitle";

function Doorway() {
    return (
        <>
            <Header isentrera={true} isacuverse={true}/>
            <PagetTitle title="Athena | Doorway to Connected World"/>
            <main>
                <section className="doorway bg" style={{ backgroundImage: `url(${require("./../assets/images/doorway-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>THIS BIG RESET</h2>
                            <h3>Doorway to Connected World</h3>
                            <p>Our future is a hyper-connected world where the most harmonious relationship between man and machine, powered by data, will engender scale, sustainability, growth, and innovation, redefining commerce, business models, operating models, businesses, industries and markets.</p>
                        </div>
                        <div className="bottom-text">
                            <p>Engagements, interactions and communication will govern and protect human behavior.</p>
                            <p>Businesses will innovate new market opportunities, new business models, participate, collaborate, drive, lead or be driven or led by market place ecosystems. Today we are preparing and readying our world for <strong>THIS BIG RESET!</strong></p>
                        </div>
                    </Container>
                </section>
                <section className="doorway-athena">
                    <Container fluid>
                        <Row xs={1} sm={2}>
                            <Col className="img-athena">
                                <img src={require('./../assets/images/athena-circle.png')} alt="Athena" className="img-responsive" />
                            </Col>
                            <Col className="text-center">
                                <ul>
                                    <li>
                                        <span className="image"><img src={require('./../assets/images/smart.png')} width="70" alt="Smart" /></span>
                                        <span>Smart</span>
                                    </li>
                                    <li>
                                        <span className="image"><img src={require('./../assets/images/limitless.png')} width="70" alt="Limitless" /></span>
                                        <span>Limitless</span>
                                    </li>
                                    <li>
                                        <span className="image"><img src={require('./../assets/images/agnostic.png')} width="90" alt="Agnostic" /></span>
                                        <span>Agnostic</span>
                                    </li>
                                    <li>
                                        <span className="image"><img src={require('./../assets/images/emerging.png')} width="80" alt="Emerging" /></span>
                                        <span>Emerging</span>
                                    </li>
                                    <li>
                                        <span className="image"><img src={require('./../assets/images/autonomous.png')} width="80" alt="Autonomous" /></span>
                                        <span>Autonomous</span>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="sub-section smart-data-architecture bg" style={{ backgroundImage: `url(${require("./../assets/images/smart-data-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>SMART DATA ARCHITECTURE</h2>
                            <h3>Enabling Scale</h3>
                        </div>
                        <Row>
                            <Col md={{ span: 5, offset: 7 }}>
                                <ul>
                                    <li>Fuses traditional and advanced data technologies</li>
                                    <li>Flexes and scales to manage limitless data volumes and limitless data forms</li>
                                    <li>Consumes, processes and analyzis tsunamis of data seamlessly, at speed</li>
                                    <li>Optimizes data management and infrastructure utilization costs</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="sub-section data-transformation bg" style={{ backgroundImage: `url(${require("./../assets/images/data-transformation-bg.png")})` }}>
                    <Container fluid>
                        <div className="section-title">
                            <h2>DATA TRANSFORMATION</h2>
                            <h3>Accelerating Growth</h3>
                        </div>
                        <Row xs={1} sm={2} md={4}>
                            <Col className="item">
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/increase-productivity.png')} alt="Increase Prductivity" /></span>
                                    <span className="text"><span className="percentage">30%+</span> Increase in business <br/>productivity</span>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/data-delivery.png')} alt="Data Delivery" /></span>
                                    <span className="text"><span className="percentage">300%+</span> Increase in integrated <br/>data delivery</span>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/cost-optimization.png')} alt="Cost Optimization" /></span>
                                    <span className="text"><span className="percentage">70%+</span> Cost optimization in <br/>enterprise data management.</span>
                                </div>
                            </Col>
                            <Col className="item">
                                <div className="inner">
                                    <span className="image"><img src={require('./../assets/images/cost-optimization-security.png')} alt="Cost Optimization Security" /></span>
                                    <span className="text"><span className="percentage">30%+</span> Cost optimization in data <br/>privacy and security.</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="sub-section emerging-technologies bg" style={{ backgroundImage: `url(${require("./../assets/images/emerging-technologies-bg.png")})` }}>
                    <Container fluid>
                        <Row>
                            <Col lg={6}>
                                <div className="section-title">
                                    <h2>EMERGING TECHNOLOGIES</h2>
                                    <h3>Empowering Innovation</h3>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <Row>
                                    <Col xs={6} className="item">
                                        <div className="inner">
                                            <span className="text">Hyper-personalizes product engagement.</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="item">
                                        <div className="inner">
                                            <span className="text">Develops and delivers new products, cross sells, and up sells</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="item">
                                        <div className="inner">
                                            <span className="text">Hyper-personalizes customer experience and engagement</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="item">
                                        <div className="inner">
                                            <span className="text">Monitors, controls, and manages risk, compliance, and operations</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
            <StickyIcons isEntrera={true} isAcuverse={true} />
        </>
    );
}
 
export default Doorway;