import React from 'react';
import { Link } from 'react-router-dom';

function Acuverse() {
    return (
        <div className="sticky-icon d-none d-lg-block acuverse-sticky">
            <Link to="/acuverse">
                <span className="image"><img src={require('./../assets/images/acuverse.png')} alt="Acuverse" /></span>
                <span className="text"><span>ACUVERSE</span></span>
            </Link>
        </div>
    );
}

function Doorway() {
    return (
        <div className="sticky-icon d-none d-lg-block doorway-sticky">
            <Link to="/doorway-to-connected-world">
                <span className="image"><img src={require('./../assets/images/doorway.png')} alt="Doorway to Connected World" /></span>
                <span className="text"><span>Doorway to <br/>Connected World</span></span>
            </Link>
        </div>
    );
}

function Entrera() {
    return (
        <div className="sticky-icon d-none d-lg-block entrera-sticky">
            <Link to="/entrera">
                <span className="image"><img src={require('./../assets/images/entrera.png')} alt="Entrera" /></span>
                <span className="text"><span>Entrera</span></span>
            </Link>
        </div>
    );
}

function AboutUs() {
    return (
        <div className="sticky-icon d-none d-lg-block about-us-sticky">
            <Link to="/about-us">
                <span className="image"><img src={require('./../assets/images/about-us.png')} alt="About Us" /></span>
                <span className="text"><span>About Us</span></span>
            </Link>
        </div>
    );
}

function StickyIcons(props) {
    const isAcuverse = props.isAcuverse;
    const isDoorway = props.isDoorway;
    const isEntrera = props.isEntrera;
    const isAboutUs = props.isAboutUs;

    return (
        <div className="sticky-icons">
            {isEntrera &&
                <Entrera />
            }
            {isAcuverse &&
                <Acuverse />
            }
            {isDoorway &&
                <Doorway />
            }
            {!isAboutUs &&
                <AboutUs />
            }
        </div>
    );
}

export default StickyIcons;